import React, {useState} from 'react'
import { ethers } from 'ethers';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Link } from '@mui/material';
import erc20abi from '../../contracts/erc20.json';
import { useAccountContext } from '../../context/accountContext';
import { BNUSD_ADDR, NFTCONTRACT_ADDR } from '../../context/Constants';


type Props = {
    openAllowanceModal: boolean
    setOpenAllowanceModal: (open: boolean) => void
}

const ModalAllowance = (props: Props) => {
  const [allowance, setAllowance] = useState(0)
  const { balance, signer, isLoading, setIsLoading, setTransactionToCheck } = useAccountContext();
  const erc20Contract = new ethers.Contract(BNUSD_ADDR, erc20abi, signer!);

  const handleClose = () => {
    props.setOpenAllowanceModal(false);
  }

  const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setAllowance(parseInt(e.target.value))
  }

  const handleClick = async () => {
    const _allowance = ethers.BigNumber.from(allowance).mul(ethers.BigNumber.from(10).pow(18))  
    const tx = await erc20Contract.approve(NFTCONTRACT_ADDR, _allowance)
    setTransactionToCheck(tx)
    setIsLoading(true)
    handleClose()
  }

    return (
    <div>                       
      <Dialog 
      open={props.openAllowanceModal && !isLoading} 
      onClose={handleClose}
      >
        <DialogTitle>ERC20 Allowance</DialogTitle>
        <DialogContent>
          { balance === 0 ?
            <DialogContentText>
              You need to have some ICZ tokens to be able to send transactions. Make sure you got ICZ in your wallet before you set an allowance.
            </DialogContentText>
          :
          <>
          <DialogContentText>
            By setting an allowance, you allow a contract to spend your tokens on your behalf. You need
            to give the Blobble smart contract an allowance to spend your bnUSD tokens. The amount you set is
            the maximum amount you can spend on your Blobble(s). You can change the allowance at any time.
          </DialogContentText>
          <DialogContent>
            <Link 
            href="https://docs.openzeppelin.com/contracts/2.x/api/token/erc20#IERC20-allowance-address-address-"
            rel="noreferrer"
            target="_blank"
            > 
              Click to learn more on ERC20 Allowances
            </Link>
          </DialogContent>
            <div 
            style={{paddingTop: "15px"}}
            >
              <TextField
              autoFocus
              margin="dense"
              id="name"
              label="set bnUSD allowance"
              type="number"
              variant="standard"
              onChange={(e:React.ChangeEvent<HTMLInputElement>)=>{handleChange(e)}}
              />
            </div>
          </>
          }
        </DialogContent>
        <DialogActions>
          {balance > 0 && <Button onClick={handleClick}>{"Set Allowance"}</Button> }
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ModalAllowance