import React, {useState, useEffect} from 'react';
import { ethers } from 'ethers';
import erc20abi from '../contracts/erc20.json';
import { BNUSD_ADDR, NFTCONTRACT_ADDR } from './Constants';

const MINTCOST: number = 1


export type globalContextValueType = {
    account: string,
    setAccount: React.Dispatch<React.SetStateAction<string>>,
    balance: number,
    setBalance: React.Dispatch<React.SetStateAction<number>>,
    blobbleBalance: number,
    setBlobbleBalance: React.Dispatch<React.SetStateAction<number>>,
    chainId: number,
    setChainId: React.Dispatch<React.SetStateAction<number>>,
    isHanaWallet: boolean,
    setIsHanaWallet: React.Dispatch<React.SetStateAction<boolean>>,
    isConnected: boolean,
    setIsConnected: React.Dispatch<React.SetStateAction<boolean>>,
    provider: ethers.providers.Web3Provider | null,
    setProvider: React.Dispatch<React.SetStateAction<ethers.providers.Web3Provider | null>>,
    signer: ethers.providers.JsonRpcSigner | null,
    setSigner: React.Dispatch<React.SetStateAction<any>>,
    mintCost: number,
    allowance: number,
    setAllowance: React.Dispatch<React.SetStateAction<number>>,
    isLoading: boolean,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    transactionToCheck: ethers.ContractTransaction | null,
    setTransactionToCheck: React.Dispatch<React.SetStateAction<ethers.ContractTransaction | null>>,
    getAllowance: () => Promise<void>,
    initialChecksDone: boolean,
}

export const AccountContext = React.createContext<globalContextValueType | null>(null)
export const useAccountContext = () => React.useContext(AccountContext)!;

export const AccountContextProvider = ({children}: {children: React.ReactNode}) => {
    const [account, setAccount] = useState<string>("")
    const [balance, setBalance] = useState<number>(0)
    const [blobbleBalance, setBlobbleBalance] = useState<number>(0)
    const [chainId, setChainId] = useState<number>(0)
    const [isHanaWallet, setIsHanaWallet] = useState<boolean>(false)
    const [isConnected, setIsConnected] = useState<boolean>(false)
    const [provider, setProvider] = useState<ethers.providers.Web3Provider|null>(null)
    const [signer, setSigner] = useState<ethers.providers.JsonRpcSigner|null>(null)
    const [allowance, setAllowance] = useState<number>(0)
    const erc20contract = new ethers.Contract(BNUSD_ADDR, erc20abi, signer!)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [transactionToCheck, setTransactionToCheck] = useState<ethers.ContractTransaction|null>(null)
    const [initialChecksDone, setInitialChecksDone] = useState<boolean>(false)
    
    // switch chain
    const switchChain = async (id:string) => {
        try {
            await window.hanaWallet.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: id }],
            });
          } catch (error) {
                console.log(error)
        }
    }
    
    // first try and get a provider from the window
    useEffect(() => {
        const _getProvider = async () => {
            const ___provider = new ethers.providers.Web3Provider(window.hanaWallet.ethereum,)
            switchChain('0x229') // 0x229 = 553 = arctic
            setProvider(___provider)
            setIsHanaWallet(true)
        }

        if(window.hanaWallet){
            if (window.hanaWallet.available) {            
                // try _getProvider() for 5 times with 0.2 second delay
                let i = 0
                const interval = setInterval(() => {
                    if (i < 5) {
                        try{
                            _getProvider()
                            i = 5
                        } catch (e) {
                            i++
                            console.log(e)
                        }
                    }
                    else {
                        clearInterval(interval)
                    }
                }, 200)

            } else {
                console.log('No Hana provider found')
            }
        }

    }, [])

    // if we have a provider, try and get the signer
    useEffect(() => {
        if (provider) {
            const _signer = provider.getSigner()
            setSigner(_signer)
            console.log('Signer found')
            
        } else {
            console.log('No signer found')
        }
       
    }, [provider])

    // if we have a signer, try and get the account
    // this will invoke the Hana wallet to connect!
    useEffect(() => {
        if (signer) {
            signer.getAddress().then((address) => {
                setAccount(address)
                setIsConnected(true)
            })
        } else {
            console.log('No account found')
        }
        setInitialChecksDone(true)
    }, [signer])

    // if we have a signer, try and get the chainId
    useEffect(() => {
        if (signer) {
            signer.getChainId().then((chainId) => {
                setChainId(chainId)
                console.log('ChainId found', chainId)
            })
        } else {
            console.log('No chainId found')
        }
    }, [signer])


    // if we have an account, try and get the balance
    useEffect(() => {
        if (account) {
            provider!.getBalance(account).then((balance) => {
                const _balance = ethers.utils.formatEther(balance)
                setBalance(parseFloat(_balance))
            })
        } else {
            console.log('No balance found')
        }
    }, [account, provider])

    // check bnusd allowance of the mint contract

    const getAllowance = async () => {
        if (account) {
            erc20contract.allowance(account, NFTCONTRACT_ADDR).then((allowance:any) => {
                setAllowance(allowance / 10 ** 18)
            })
        } else {
            console.log('No allowance found')
        }
    }

    useEffect(() => {
        getAllowance()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account])

    const value: globalContextValueType = {
        account,
        setAccount,
        balance,
        setBalance,
        blobbleBalance,
        setBlobbleBalance,
        chainId,
        setChainId,
        isHanaWallet,
        setIsHanaWallet,
        isConnected,
        setIsConnected,
        provider,
        setProvider,
        signer,
        setSigner,
        mintCost: MINTCOST,
        allowance: allowance,
        setAllowance: setAllowance,
        isLoading,
        setIsLoading: setIsLoading,
        transactionToCheck: transactionToCheck,
        setTransactionToCheck: setTransactionToCheck,
        getAllowance: getAllowance,
        initialChecksDone: initialChecksDone,
    }

    return(
        <AccountContext.Provider value={value}>
            {children}  
        </AccountContext.Provider>
    )

}

// export const useBalance = (initial: number) => React.useState(0)
// export type UseBalanceType = ReturnType<typeof useBalance> 

// const BalanceContext = React.createContext<UseBalanceType | null>(null)
// export const useBalanceContext = () => React.useContext(BalanceContext)!;

// export const BalanceContextProvider = ({children}: {children: React.ReactNode}) => (    
//     <BalanceContext.Provider value={useBalance(0)}>
//         {children}  
//     </BalanceContext.Provider>
// )

declare const window: any;



// export const provider = window.hanaWallet?.ethereum