import React, {useEffect, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { ethers } from 'ethers'
import NFTabi from '../contracts/mainBlobble.json'
import scoresAbi from '../contracts/scoresContract.json'
import { NFTCONTRACT_ADDR, SCORESCONTRACT_ADDR } from '../context/Constants'
import FormDialog from './modals/setForSaleModal'
import { useAccountContext } from '../context/accountContext';


type BlobbolInstanceProps = {
    id: number | null
    data: string | null
}

function BlobbolInstanceMarketOwner(props:BlobbolInstanceProps) {
    const {signer} = useAccountContext();
    
    const id = props.id
    const data = props.data

    const nftContract = new ethers.Contract(NFTCONTRACT_ADDR, NFTabi, signer!);   
    const scoresContract = new ethers.Contract(SCORESCONTRACT_ADDR, scoresAbi, signer!)
    
    const [age, setAge] = React.useState(0)
    // const [latestBlock, setLatestBlock] = useState(0)
    // eslint-disable-next-line
    const [ score, setScore ] = useState(0)
    const [ isForSale, setIsForSale] = useState<boolean>(false)
    const [ priceBlobble, setPriceBlobble ] = useState<number>(0)
    
    // // function for getting the latest block
    // const getBlock = async () => {
    //     const _block = await provider!.getBlockNumber()
    //     setLatestBlock(_block)
    // }
    
    // setInterval(getBlock, 10000)

 
    useEffect(() => {
        let cancel = false;
        const _getAge = async() => {
            nftContract.getAge(id).then((res: number) => {
                if (cancel) return;
                setAge(res)
            })
        }
        if(signer){
            _getAge()
        }
        return () => {
            // Cleanup logic here
            cancel = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signer])

 
    useEffect(() => {
        let cancel = false;

        const _isForSale = async() => {
            nftContract.getIsForSale(id).then((res: boolean) => {
                if (cancel) return;
                setIsForSale(res)
            })
        }
        if(signer){
            _isForSale()
        }
        return () => {
            // Cleanup logic here
            cancel = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [score, signer])


    useEffect(() => {
        const _priceBlobble = async() => {
            nftContract.priceBlobble(id).then((res: string) => {
                let _res = parseInt(res) / 10 ** 18
                setPriceBlobble(_res)})
        }
        if (isForSale && signer){
            _priceBlobble()
        }
        return () => {
            // Cleanup logic here
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [score,isForSale, signer])
    

    useEffect(() => {
        let cancel = false;
        
        const getScores = async () => {
            
            scoresContract.scoreIndex(props.id).then((res:any)=> {
                if (cancel) return;
                let _temp = (res[0] / 10 + res[1] / 10 + res[2] /10) / 3
                setScore(Math.round(_temp))
            })
        }
        getScores()

        return () => {
            cancel = true;
            // Cleanup logic here
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [age])
 
    const setApperance = () =>{
        const bodyMain = document.querySelectorAll<HTMLElement>(`#Blobble_MarketOwned${id?.toString()}`)
        
        let bodyOriginalHeight: number;

        bodyMain.forEach(body => {
            let mainBody = body.children[1] as HTMLElement       
            bodyOriginalHeight = parseInt(mainBody.style.height.split('px')[0] )
        })
   
        // mouth sadder, also body height decreases
        // !! score is the amount of pixels the bar is moved
        if (score < Math.abs(5) && score !== 0 ) {
            console.log('joe , score: ', score)
            bodyMain.forEach(body => {
                let mainBody = body.children[1].children[0] as HTMLElement
                let mouth = body.children[1].children[0].children[4] as HTMLElement
                let sadMouth = "/ 100% 100% 50% 1%";
                let mouth_radius_1 = mouth.style.borderRadius.split('/')[0] 
                mouth.style.borderRadius = mouth_radius_1 + sadMouth
                
                if (bodyOriginalHeight > 110) {
                    mainBody.style.height = (bodyOriginalHeight * 0.8).toString() + 'px'
                } 
            })
        }

        // first a bit sad --> only eyes sad, mouth not yet
        // !! score is the amount of pixels the bar is moved
        if (score < Math.abs(45) && score !== 0 ) {
            bodyMain.forEach(body => {
                let leftEye = body.children[1].children[0].children[2] as HTMLElement
                let rightEye = body.children[1].children[0].children[3] as HTMLElement

                let sadLeftEye = "90% 10% 40% 47% / 90% 47% 47% 40%"
                let sadRightEye = "8% 48% 21% 24% / 62% 68% 33% 42%"
                 
                leftEye.style.borderRadius = sadLeftEye
                rightEye.style.borderRadius = sadRightEye
                leftEye.style.top = "20px"
                rightEye.style.top = "20px"
            })
        }

    }
    
    // this
    useEffect(() => {
        
        try {
            setApperance()
        } catch(e) {
            console.log(e)
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [score])

    return (
        <div> 
            <div id={`Blobble_MarketOwned${id?.toString()}`} className="blobbleRenderContainerOrphanage" >       
                <div style={{
                    height:"auto", 
                    background:"white", 
                    borderRadius:"5px",
                    padding:"10px",
                    display:"flex",
                    flexDirection:"column",
                }}>
                    <div className="titleBlobbol">blobble {id}</div>
                    <div className="">age: {age.toString()} blocks</div>  
                    <div style={{marginTop:'0px'}}>
                        <div>score: {score}</div>
                        {
                            isForSale && 
                            <div>price: {priceBlobble} bnUSD</div>

                        }
                    </div>
                    <div style={{marginTop:"12px"}}>
                        <FormDialog data={data} id={id} score={score}/>
                    </div>
                </div>
                {/* here is the blobble */} 
                <div className="blobbolCharacter">{ReactHtmlParser(data!)}</div>
            </div>
        </div> 
    )
}

export default BlobbolInstanceMarketOwner
