import React,{memo} from 'react'
import  {useAccountContext}  from '../context/accountContext'

class BlobbolGenes {
    colorMainHsl= "";
    colorMain = "#79018C";
    colorSec = "rgb(192, 70, 0)";
    colorThird = "rgb(128, 238, 95)";
    pupilColor = "";
    bodyWidth = "";
    bodyHeight = "";
    bodyRadiusOne = "";
    bodyRadiusTwo = "";
    bodyRadiusThree = "";
    bodyRadiusFour = "";
    bodyFillAWidth = "";
    bodyFillAHeight = "";
    bodyFillAY = "";
    bodyFillAX = "";
    bodyFillBWidth = "";
    bodyFillBHeight = "";
    bodyFillBY = "";
    bodyFillBX = "";
    pupilsSide = "";
    pupilsMovement = "";
    leftEyePosX = "";
    leftEyePosY = "";
    rightEyePosX = "";
    rightEyePosY = "";
    mouthWidth = "";
    mouthHeight = "";
    mouthPosFromTop = "";
    mouthPosFromLeft =""
}

export const blobbol = new BlobbolGenes()

// for the pupils
function getRandomDarkColor() {
    return 'rgb(' + 
      (Math.floor(Math.random()*56)+getRandomNumber(2,190)) + ', ' +
      (Math.floor(Math.random()*56)+getRandomNumber(2,170)) + ', ' +
      (Math.floor(Math.random()*56)+getRandomNumber(2,170)) + ')' 
    ;
}

const randomizeColor = () => {
    return Math.floor(Math.random()*16777215).toString(16);
}

const randomizeHslColor = (h_min:number,h_max:number, l_min:number, l_max:number) :string => {
    return `hsl(${Math.floor(Math.random()* (h_max - h_min)+ h_min).toString()},100%,
    ${Math.floor(Math.random()* (l_max - l_min)+ l_min).toString()}%)`
}

const getRandomNumber = (min: number, max: number) => {
    return Math.random() * (max - min) + min;
}

const randomizePupils = (mov: string, side: string) => {
    if (mov === "movement"){
        let pupilmovement = ['moveupdown', 'moveleftright', 'moveround']
        return pupilmovement[Math.floor(Math.random() * pupilmovement.length)];
    }
    if(side === "side"){
        let pupilside = ['-4', '0', '5', '2']
        return pupilside[Math.floor(Math.random()*pupilside.length)]
    } else return ""
}

// generate a random blobbol object and show on site
const generateBlobbol = () => {
    //first generate random values and set them to the above created blobbol instance
    //then use the values of that instance to set it to the to blobbel on the screen
    
    // random values to instance
    blobbol.colorMainHsl = randomizeHslColor(1,100,10,100)
    //blobbol.colorMain = '#'+randomizeColor()
    blobbol.colorSec = '#'+randomizeColor()
    blobbol.colorThird = '#'+randomizeColor()
    blobbol.pupilColor = getRandomDarkColor()
    // values of instance to screen
    document.documentElement.style.setProperty('--color-main-hsl', blobbol.colorMainHsl);
    //document.documentElement.style.setProperty('--color-main', blobbol.colorMain);
    document.documentElement.style.setProperty('--color-sec', blobbol.colorSec);
    document.documentElement.style.setProperty('--color-third', blobbol.colorThird);
    document.documentElement.style.setProperty('--pupil-color', blobbol.pupilColor);
    // random values to instance
    blobbol.bodyWidth = getRandomNumber(135,150)+"px"
    blobbol.bodyHeight = getRandomNumber(135,150)+"px"
    // values of instance to screen
    document.documentElement.style.setProperty('--body-width', blobbol.bodyWidth);
    document.documentElement.style.setProperty('--body-height', blobbol.bodyHeight);
    // random values to instance 
    blobbol.bodyRadiusOne = getRandomNumber(30,60)+"%"
    blobbol.bodyRadiusTwo = getRandomNumber(30,60)+"%"
    blobbol.bodyRadiusThree = getRandomNumber(30,35)+"%"
    blobbol.bodyRadiusFour = getRandomNumber(16,30)+"%"
    // values of instance to screen
    document.documentElement.style.setProperty('--body-radius-one', blobbol.bodyRadiusOne);
    document.documentElement.style.setProperty('--body-radius-two', blobbol.bodyRadiusTwo);
    document.documentElement.style.setProperty('--body-radius-three', blobbol.bodyRadiusThree);
    document.documentElement.style.setProperty('--body-radius-four', blobbol.bodyRadiusFour);
    // random values to instance
    blobbol.bodyFillAWidth = getRandomNumber(70,90)+"%"
    blobbol.bodyFillAHeight = getRandomNumber(70,90)+"%"
    blobbol.bodyFillAY = getRandomNumber(3,45)+"%"
    blobbol.bodyFillAX = getRandomNumber(40,80)+"%"
    // values of instance to screen
    document.documentElement.style.setProperty('--body-fill-a-width', blobbol.bodyFillAWidth);
    document.documentElement.style.setProperty('--body-fill-a-height', blobbol.bodyFillAHeight);
    document.documentElement.style.setProperty('--body-fill-a-y', blobbol.bodyFillAY);
    document.documentElement.style.setProperty('--body-fill-a-x', blobbol.bodyFillAX);
    // random values to instance
    blobbol.bodyFillBWidth = getRandomNumber(60,80)+"%"
    blobbol.bodyFillBHeight = getRandomNumber(60,80)+"%"
    blobbol.bodyFillBY = getRandomNumber(2,90)+"%"
    blobbol.bodyFillBX = getRandomNumber(1,50)+"%"
    // values of instance to screen
    document.documentElement.style.setProperty('--body-fill-b-width', blobbol.bodyFillBWidth);
    document.documentElement.style.setProperty('--body-fill-b-height', blobbol.bodyFillBHeight);
    document.documentElement.style.setProperty('--body-fill-b-y', blobbol.bodyFillBY);
    document.documentElement.style.setProperty('--body-fill-b-x', blobbol.bodyFillBX);
    // random values to instance
    blobbol.leftEyePosX = getRandomNumber(10,17)+"%"
    blobbol.leftEyePosY = getRandomNumber(9,13)+"%"
    // values of instance to screen
    document.documentElement.style.setProperty('--left-eye-position-x', blobbol.leftEyePosX);
    document.documentElement.style.setProperty('--left-eye-position-y', blobbol.leftEyePosY);
    // random values to instance
    blobbol.rightEyePosX = getRandomNumber(10,17)+"%"
    blobbol.rightEyePosY = getRandomNumber(9,13)+"%"
    // values of instance to screen
    document.documentElement.style.setProperty('--right-eye-position-x', blobbol.rightEyePosX);
    document.documentElement.style.setProperty('--right-eye-position-y', blobbol.rightEyePosY);
    // random values to instance
    blobbol.pupilsMovement = randomizePupils('movement', '')
    blobbol.pupilsSide = randomizePupils("", "side")+"px"
    // values of instance to screen
    document.documentElement.style.setProperty('--pupil-animate', blobbol.pupilsMovement);
    document.documentElement.style.setProperty('--pupils-side', blobbol.pupilsSide);
    if (blobbol.pupilsMovement === "moveround"){
        document.documentElement.style.setProperty('--pupil-animate-timing', "linear");
    }
    
    // random values to instance
    blobbol.mouthWidth = getRandomNumber(54,51)+"px"
    blobbol.mouthHeight = getRandomNumber(20,26)+"px"
    blobbol.mouthPosFromTop = getRandomNumber(34,38)+"%"
    blobbol.mouthPosFromLeft = getRandomNumber(20,40)+"%"
    
    // values of instance to screen
    document.documentElement.style.setProperty('--mouth-width',blobbol.mouthWidth  );
    document.documentElement.style.setProperty('--mouth-height', blobbol.mouthHeight );
    document.documentElement.style.setProperty('--mouth-pos-from-top', blobbol.mouthPosFromTop);
    document.documentElement.style.setProperty('--mouth-pos-from-left', blobbol.mouthPosFromLeft );
} 

function Landing() {
    const { isConnected } = useAccountContext();

    (async() => {
        while(true){
            await new Promise(resolve => setTimeout(resolve, 5000))
            generateBlobbol()
        }
    })()
    
    generateBlobbol()
    
    return (
        <div className='landingContainer'>
            <div className='landingContent'>
                <h1>Welcome to the Blobble Universe</h1>
                {isConnected && <h1>dev: there is a wallet connected</h1>}
                <p>Blobbles are cute little creatures that live on the Snow Blockchain. Their health status is also kept on the blockhain.
                    You can mint Blobbles yourself but you do need to take care of them. Otherwise
                    they will become unhappy and end up in the orphanage.<br></br><br></br>The age of a Blobble
                    is given by the number of blocks it has been on the blockchain. How old can your
                    Blobble get?<br></br><br></br>
                    Log in using your Hana Wallet and start playing!
                </p>
            </div>
            <div style={{height:'220px'}}>
                <div className="character">
                    <div className="body-fill-a" ></div>
                    <div className="body-fill-b" ></div>
                    <div className="left-eye" ><div className='pupil' ></div></div>
                    <div className="right-eye" ><div className='pupil'></div></div>
                    <div className="mouth" ></div>
                </div>
            </div>
        </div>
    )
}

export default memo(Landing)
