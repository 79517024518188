import React, {useEffect, useState} from 'react'
import ReactHtmlParser from 'react-html-parser'
import { ethers } from 'ethers'
import NFTabi from '../contracts/mainBlobble.json'
import scoresAbi from '../contracts/scoresContract.json'
import { NFTCONTRACT_ADDR, ADOPTER_CONTRACT_ADDR, SCORESCONTRACT_ADDR } from '../context/Constants'
import adopterContractABI from '../contracts/adopter.json'
import { useAccountContext } from '../context/accountContext'
import LoadingButton from '@mui/lab/LoadingButton';

type BlobbolInstanceProps = {
    id: number | null
    data: string | null
}


function BlobbolOrphanInstance(props:BlobbolInstanceProps) {
    const {provider, signer } = useAccountContext()
    const id = props.id
    const data = props.data
    const nftContract = new ethers.Contract(NFTCONTRACT_ADDR, NFTabi, provider!);   
    const adopterContract = new ethers.Contract(ADOPTER_CONTRACT_ADDR, adopterContractABI, signer!)
    const scoresContract = new ethers.Contract(SCORESCONTRACT_ADDR, scoresAbi, provider!)
    
    const [age, setAge] = useState(0)
    // const [latestBlock, setLatestBlock] = useState(0)
    // eslint-disable-next-line
    const [loading, setLoading] = useState(false);
    const [ score, setScore ] = useState(0)
    // eslint-disable-next-line 
    // const [ neededPriceToFill, setNeededPriceToFill ] = useState(0)

    const [ menuActive, setMenuActive ] = useState(false)

    // function for getting the latest block
    // const getBlock = async () => {
    //     const block = await PROVIDER.getBlockNumber()
    //     setLatestBlock(block)
    // }
    // setInterval(getBlock, 10000)

    useEffect(() => {
        const getAge = async() => {
            await nftContract.getAge(id).then((res: number) => setAge(res))
        }
        getAge()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    useEffect(() => {
        const getScores = async () => {
            scoresContract.scoreIndex(props.id).then((res:any)=> {
                let _temp = (res[0] / 10 + res[1] / 10 + res[2] /10) / 3
                setScore(Math.round(_temp))
            })
        }
        try {
            getScores()
            
        } catch(e) {
            console.log(e)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [age])
    
    // make sad Blobble for in the orphanage
    const setApperance = () =>{
        const bodyMain = document.querySelectorAll<HTMLElement>(`#Blobble_Orphan${id?.toString()}`)
        
        try{
            bodyMain.forEach(body => {
                let mainBody = body.children[0].children[1].children[0] as HTMLElement
                
                let mouth = body.children[0].children[1].children[0].children[4] as HTMLElement
                let sadMouth = "/ 100% 100% 50% 1%";
                let mouth_radius_1  = mouth.style.borderRadius.split('/')[0]
                
                mouth.style.borderRadius = mouth_radius_1 + sadMouth
                mainBody.style.height = '90px'

                let leftEye = body.children[0].children[1].children[0].children[2] as HTMLElement
                let rightEye = body.children[0].children[1].children[0].children[3] as HTMLElement

                let sadLeftEye = "90% 10% 40% 47% / 90% 47% 47% 40%"
                let sadRightEye = "8% 48% 21% 24% / 62% 68% 33% 42%"
                 
                leftEye.style.borderRadius = sadLeftEye
                rightEye.style.borderRadius = sadRightEye
                leftEye.style.top = "20px"
                rightEye.style.top = "20px"
            })
        } catch(e) { 
            console.log(e)
        }

    }
    
    useEffect(() => {
        setApperance()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [age])

    const adopt = (_id: number) => {
        let options = {
            //value: utils.parseEther(neededPriceToFill.toString()), 
            //value: utils.parseEther("1"), 
            gasLimit : 900000,
        }
        try {
            adopterContract.callAdoptFunction(NFTCONTRACT_ADDR,_id,options)
        } catch(e) {
            console.log(e)
        }
    }

    return (
        <div id={`Blobble_Orphan${id?.toString()}`} 
            className={menuActive ? "blobbleRenderContainerOrphanage blobbleRenderContainerOrphanageMenuOpened" : "blobbleRenderContainerOrphanage"}    
            onClick={()=>setMenuActive(!menuActive)}
            >
            <div>
                <div className='orphanInfoBox'>
                    <div className="titleBlobbol">blobble {id}</div>
                    <div className="">age: {age.toString()} blocks</div>  
                    <div style={{marginTop:'12px'}}>
                        <div>score: {score}</div>
                
                        {/* here is the blobble */} 
                    
                    </div>
                </div>
                <div  className="blobbolCharacter">{ReactHtmlParser(data!)}</div>

                { menuActive && 
                    <div style={{marginTop:"auto"}}>
                        <LoadingButton
                            style={{marginTop: '20px', marginBottom: '2px', background:"#282c34", gridArea:"claimbutton", justifySelf:"center"}}
                            onClick={()=>adopt(id!)}
                            loading={loading}
                            variant="contained"
                            size="small"
                        >
                        adopt </LoadingButton>
                    </div>
                }
            </div>
        </div>
    )
}

export default BlobbolOrphanInstance
