import React, {useEffect, useState} from 'react'
import ReactHtmlParser from 'react-html-parser'
import { ethers } from 'ethers'
import NFTabi from '../contracts/mainBlobble.json'
import scoresAbi from '../contracts/scoresContract.json'
import adopterContractABI from '../contracts/adopter.json'
import { NFTCONTRACT_ADDR, SCORESCONTRACT_ADDR, ADOPTER_CONTRACT_ADDR } from '../context/Constants'
import LoadingButton from '@mui/lab/LoadingButton';
import { useAccountContext } from '../context/accountContext';


type BlobbolInstanceProps = {
    id: number | null
    uri: string | null
    price: number | null
}


function BlobbolInstanceMarketForSale(props:BlobbolInstanceProps) {
    const { signer, account } = useAccountContext();

    const id = props.id
    const uri = props.uri
    const price = props.price

    const nftContract = new ethers.Contract(NFTCONTRACT_ADDR, NFTabi, signer!); 
    const scoresContract = new ethers.Contract(SCORESCONTRACT_ADDR, scoresAbi, signer!)
    const adopterContract = new ethers.Contract(ADOPTER_CONTRACT_ADDR, adopterContractABI, signer!)
    const [age, setAge] = React.useState(0)
    // eslint-disable-next-line
    const [latestBlock, setLatestBlock] = useState(0)

    const [ score, setScore ] = useState(0)
    const [ neededPriceToFill, setNeededPriceToFill ] = useState(0)


    // function for getting the latest block
    // const getBlock = async () => {
    //     const block = await PROVIDER.getBlockNumber()
    //     setLatestBlock(block)
    // }
    // setInterval(getBlock, 10000)

    // function to check tx
    // const checkTx = async (tx:string) => {
    //     let res = await PROVIDER.waitForTransaction(tx)
    //     let status = res.status
    //     if(status === 1){
    //         setLoading(false)
    //     } else {
    //         alert("Something went wrong with the transaction.")
    //         setLoading(false)
    //     }
    // }


    useEffect(() => {
        let cancel = false;
        
        const getAge = async() => {
            nftContract.getAge(id).then((res: number) => {
                if(cancel) return;
                setAge(res)
            })
        }
        getAge()
        return () => {
            // Cleanup logic here
            cancel = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [latestBlock])
    
    useEffect(() => {
        let cancel = false;

        const getScores = async () => {
            scoresContract.scoreIndex(props.id).then((res:any)=> {
                if(cancel) return;
                let _temp = (res[0] / 10 + res[1] / 10 + res[2] /10) / 3
                setScore(Math.round(_temp))
            })
        }
        getScores()
        return () => {
            // Cleanup logic here
            cancel = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [age])
    
    useEffect(() => {
        const setApperance = () =>{
            const bodyMain = document.querySelectorAll<HTMLElement>(`Blobble_MarketForSale${id?.toString()}`)
    
            let bodyOriginalHeight: number;
            
            bodyMain.forEach(body => {
                let mainBody = body.children[1].children[3].children[0] as HTMLElement         
                bodyOriginalHeight = parseInt(mainBody.style.height.split('px')[0] )
            })
       
            // mouth sadder, also body height decreases
            // * score is the amount of pixels the bar is moved
            if (score > 73) {
                bodyMain.forEach(body => {
                    let mainBody = body.children[1].children[0] as HTMLElement
    
                    console.log('mainBody')
                    let mouth = body.children[1].children[0].children[4] as HTMLElement
                    let sadMouth = "/ 100% 100% 50% 1%";
                    let mouth_radius_1 = mouth.style.borderRadius.split('/')[0] 
                    mouth.style.borderRadius = mouth_radius_1 + sadMouth
                    
                    if (bodyOriginalHeight > 110) {
                        mainBody.style.height = (bodyOriginalHeight * 0.8).toString() + 'px'
                    } 
                })
            }
            // first a bit sad --> only eyes sad, mouth not yet
            // * score is the amount of pixels the bar is moved
            if (score > 45) {
                bodyMain.forEach(body => {
                    let leftEye = body.children[1].children[0].children[2] as HTMLElement
                    let rightEye = body.children[1].children[0].children[3] as HTMLElement
    
                    let sadLeftEye = "90% 10% 40% 47% / 90% 47% 47% 40%"
                    let sadRightEye = "8% 48% 21% 24% / 62% 68% 33% 42%"
                     
                    leftEye.style.borderRadius = sadLeftEye
                    rightEye.style.borderRadius = sadRightEye
                    leftEye.style.top = "20px"
                    rightEye.style.top = "20px"
                })
            }
     
        }
        setApperance()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [score, signer])



    useEffect(() => {
        const scoreNeededToGetToHealthyPoint = () => {
            let cyclesPerIcz = 3
            let _temp:any;
            _temp = (Math.abs((score)/cyclesPerIcz) + 100) / 100
            setNeededPriceToFill(_temp)
        }
        scoreNeededToGetToHealthyPoint()
        return () => {
            // Cleanup logic here
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [score])

    const handleClickBuyBlobble = async () => {
        // const _adr = account
        // const _v = price! / 10 ** 18
        // const _value = ethers.utils.parseEther(_v.toString())
        // console.log(props.id, _adr, _value)
        // try { 
            
        // let tx = await nftContract.setForSale(props.id, 100000) 
        
        let tx = await adopterContract.sellBlobble(NFTCONTRACT_ADDR,props.id) 
            
            console.log(tx)
        // } catch(e){ 
        //     console.log(e)
       
        // } 
    }

    return (
        <div> 
            <div id={`Blobble_MarketForSale${id?.toString()}`} className="blobbleRenderContainerOrphanage" >       
                <div style={{
                    height:"auto", 
                    background:"white", 
                    borderRadius:"5px",
                    padding:"10px",
                    display:"flex",
                    flexDirection:"column",
                }}>
                    <div className="titleBlobbol">blobble {id}</div>
                    <div className="">age: {age.toString()} blocks</div>  
                    <div style={{marginTop:'12px'}}>
                    <div>score: {score}</div>
                    <div>cost full health: {Math.round(neededPriceToFill)} bnUSD</div>
                    <div>price: {price!/10**18} bnUSD</div>
                    </div>
                    <div style={{marginTop:"auto"}}>
                        <LoadingButton
                            style={{marginTop: '20px', marginBottom: '2px', background:"#282c34", gridArea:"claimbutton", justifySelf:"center"}}
                            onClick={handleClickBuyBlobble}
                            loading={false}
                            variant="contained"
                            size="small"
                        >
                        Buy </LoadingButton>
                    </div>
                </div>
                {/* here is the blobble */} 
                <div  className="blobbolCharacter">{ReactHtmlParser(uri!)}</div>
            </div>
        </div> 
    )
}

export default BlobbolInstanceMarketForSale
