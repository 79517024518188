import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
// eslint-disable-next-line

import { useAccountContext} from "./context/accountContext";
import Main from './components/Main'
import Landing from './components/Landing';
import Market from './components/Market';
import Navbar from './components/Navbar';
//import WalletConnectProvider from "@walletconnect/web3-provider";

declare const window: any;

function App() {
  
  // const PROVIDER = new ethers.providers.Web3Provider(window.hanaWallet.ethereum)
  // console.log(PROVIDER.getSigner())
  // const [toggleOnAccountChange, setToggleOnAccountChange] = useState(false);
  const {isConnected} = useAccountContext();

  // const SIGNER = PROVIDER.getSigner()
  // Subscribe to accounts change
  // PROVIDER.on("accountsChanged", (accounts: string[]) => {
  //   setToggleOnAccountChange(!toggleOnAccountChange);
  //   console.log(accounts);
  // });

  // Subscribe to chainId change
  // PROVIDER.on("chainChanged", (chainId: number) => {
  //   console.log(chainId);
  // });

  // Subscribe to session disconnection
  // PROVIDER.on("disconnect", (code: number, reason: string) => {
  //   console.log(code, reason);
  // });
  
  // const [isConnected, setIsConnected] = useState(false);
  // eslint-disable-next-line
  // const [blobbolBalance, setBlobbolBalance] = useBalanceContext()

  // ACCOUNT-CONTEXT PROVIDED FROM INDEX.TSX SO ALL COMPONENTS CAN ACCESS IT
  // eslint-disable-next-line
  // const [account, setAccount] = useAccountContext(); //first is account-context, second is setAccount-context
  
  // listen for changes in the user's account
  // window.ethereum.on('accountsChanged', () => {
  //   setToggleOnAccountChange(!toggleOnAccountChange);
  //   // setBlobbolBalance(0)
  // });

  // check if hana is connected
  // useEffect(() => {
  //   (async() => {
  //     await PROVIDER.listAccounts().then(
  //       (r)=> r.length > 0? setIsConnected(true): setIsConnected(false))
  //   })();
  //   // eslint-disable-next-line
  // }, [toggleOnAccountChange]); // effect is envoked when window.ethereum.on listener is activated

  // set address and balance for front end
  // useEffect(()  => {
  //   (async() => {
  //     if(isConnected){
  //     try{

  //       const address = await SIGNER.getAddress();
  //       setAccount(address); // is setting the context for all components
  //     }catch(e){
  //       console.log('could not connect, please connect to metamask.')
  //     }
  //   }
  //   })()
  //   // eslint-disable-next-line
  // },[isConnected, toggleOnAccountChange]) // effect is envoked when window.ethereum.on listener is activated 
  
  // get connected chainId
  // useEffect(() => {
    
  //   (async() => {
  //     console.log('trying to get chainId')

  //     if(isConnected){
  //       try{
  //         const chainId = await PROVIDER.getNetwork();
  //         console.log(chainId)
  //       }catch(e){
  //         console.log('could not connect, please connect to metamask.')
  //       }
  //     }
  //   })()
  //   // eslint-disable-next-line
  // },[isConnected]) // effect is envoked when window.ethereum.on listener is activated

  const listenScrollEvent = () => {
    const clouds  = document.querySelectorAll<HTMLElement>("#clouds")
    let pos = window.pageXOffset
 
    clouds.forEach(cloudEl => {
      cloudEl.style.backgroundPosition = +(400+pos*Math.pow(1.06,5)) + 'px 140px, -30px 190px, top center'          
    })

  };

  window.addEventListener('scroll', listenScrollEvent);
  
  return (

      <Router>
        { isConnected && <Navbar/>}

        <Routes>
          <Route path="/" element={<Landing/>}/>
          <Route path="/app" element={<Main/>}/>
          <Route path="/market" element={<Market/>}/>
        </Routes>
      </Router>

  )
}

export default App;
