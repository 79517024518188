import React,{useState, useEffect} from 'react'
import NFTabi from '../contracts/mainBlobble.json'
import {ethers, BigNumber} from 'ethers'
import BlobbolInstance from './BlobbolInstance';
import { NFTCONTRACT_ADDR } from '../context/Constants';
import { useAccountContext } from '../context/accountContext';

// create a mapping of the nft ids to the metadata
const IdMetaDataMapping = new Map()

function GetBlobbols() {
    const { signer, account, blobbleBalance, setBlobbleBalance } = useAccountContext()
    const nftContract = new ethers.Contract(NFTCONTRACT_ADDR, NFTabi, signer!);       
    // eslint-disable-next-line
    const [ mapLength, setMapLength ] = useState<number>(0)
 
    const getAmountNftsOwnedByAccount = async () => {
        try{ await nftContract.balanceOf(account).then(
            (balance:number) => setBlobbleBalance(parseInt(balance.toString())))
        } catch(e){} 
    }
    useEffect(() => {
        getAmountNftsOwnedByAccount()
        // eslint-disable-next-line
    }, [account])

    // const filter = {
    //     address: NFTCONTRACT_ADDR,
    //     topics: [
    //         utils.id("Transfer(address,address,uint256)")
    //     ]
    // }
    // listen for events on the blockchain 
    // PROVIDER.on(filter, (log, event) => {
    //     // Emitted whenever onchain balance changes
    //     getAmountNftsOwnedByAccount()
    //     //console.log("new nft minted or received, if nothing changed on screen refresh page manualy")
    // })
    
    // call contract for every blobbol of account and map id to the metadata for every blobbol token
    const getNftIds = async () => {
        // first clear the map otherwise the list wont refresh on account switch (will just add the blobbol renders)
        IdMetaDataMapping.clear()
        
        if(blobbleBalance > 0){
            for(let i = 0; i < blobbleBalance; i++){
                let _id: number;
                let _uri: string;
                
                // get nft id from contract by index (loops through all nfts using lenght of nftBalance)
                try{
                    await nftContract.tokenOfOwnerByIndex(account, i).then(
                    (id:BigNumber) => _id = parseInt(id.toString()))
                    // add nft id to mapping as key with temporary value
                    IdMetaDataMapping.set(_id!,"")
                } catch(e){ console.log('error getting nft id: ', i )}
                
                // use the retrieved nft-id to get the tokenuri (metadata) of the nft from the contract
                try{
                    await nftContract.tokenURI(_id!).then((uri:string) => (_uri = uri))
                    const _uri_decoded = decodeURIComponent(_uri!.replace(/\s+/g, '').replace(/[0-9a-f]{2}/g, '%$&')).substring(2);  
                    IdMetaDataMapping.set(_id!,_uri_decoded)
            
                } catch(e){ console.log('error getting nft uri')}
                // I used this to cause an rerender of the renderlist below
            }
            setMapLength(IdMetaDataMapping.size)
        
        }
    }

    useEffect(() => {
        if(blobbleBalance > 0){
            getNftIds()
        }
    // eslint-disable-next-line
    }, [blobbleBalance])

   
    const RenderBlobbols = () => {
        // to render correctly the keys of the maps are put in an array
        let nums = []
        let vals:string[] = [] 
        // eslint-disable-next-line        
        for (let [key, val] of IdMetaDataMapping) {
            nums.unshift(key as never)
            let _val = IdMetaDataMapping.get(key)
            vals.unshift(_val)
        }

        // THIS IS WHERE THE ACTUAL RENDERING OF THE BLOBBOLS HAPPENS
        // check if account has nfts, if so return blobbol instance for every nft
        if( IdMetaDataMapping.size > 0){
            return( <>{nums.map((id,index)=> (<BlobbolInstance key={id} id={id} data={vals[index]}/>))} </> )
        }
        else { return <> <div className="titleBlobbol">{"no blobbols found :("}</div> </> }
    }

    return (
        <div className='renderBlobbles'>
            {/* <div>Your Blobbles</div> */}
            <div className='wrapperBlobbleRenders'>
                <RenderBlobbols/>  
            </div>
        </div>
    )
}

export default GetBlobbols
