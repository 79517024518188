import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './Blobbol.css'
import App from './App';
//import reportWebVitals from './reportWebVitals';
import { AccountContextProvider }  from './context/accountContext'
import Landing from './components/Landing'
import LoadingStateModal from './components/modals/LoadingStateModal';

declare const window:any;

if(window.hanaWallet){
  if(window.hanaWallet?.available){    
    ReactDOM.render(
          <AccountContextProvider>
            <LoadingStateModal/>
            <App/>
          </AccountContextProvider>
        ,
        document.getElementById('root')
    );
    }
} else {
  ReactDOM.render(
    <AccountContextProvider>
      <Landing/>
    </AccountContextProvider>,
    document.getElementById('root')
  );
}




// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
