import React,{useState, useEffect} from 'react';
import {ethers, utils, BigNumber} from 'ethers'
import {STAKING_CONTRACT_ADDR} from '../context/Constants'
import StakingContract from '../contracts/stakingContract.json'
import { useAccountContext } from '../context/accountContext';
import FormControl  from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import LoadingButton from '@mui/lab/LoadingButton';
import Slider from '@mui/material/Slider';


function Staking() {
    const { account, signer,  setIsLoading, setTransactionToCheck, isLoading } = useAccountContext();
    const stakingContract = new ethers.Contract(STAKING_CONTRACT_ADDR, StakingContract, signer!);   
    // const signStakingContract = new ethers.Contract(STAKING_CONTRACT_ADDR, StakingContract, signer!);   
    const [stakedAmount, setStakedAmount] = useState("0");
    const [totalStaked, setTotalStaked] = useState("0");
    const [rewards, setRewards] = useState("0");
    const [balanceBST, setBalanceBST] = useState<string>("0");    
    // eslint-disable-next-line
    const [loading, setLoading] = useState(false);
    const [selectedReward, setSelectedReward] = useState("ICZ");
    const [sliderValue, setSliderValue] = useState(0)
    const [claimableBST, setClaimableBST] = useState(0)
    const [lockedBST, setLockedBST] = useState(0)
    const [vestingPeriod, setVestingPeriod] = useState("0")
    const [openStakeMenu, setOpenStakeMenu] = useState(false)
   
    // temp test call
    // useEffect(() => {
    //     const go = async () => {
    //         const res = await stakingContract.getBlobbleLiquidityWallet()
    //         console.log(res)
    //     }
    //     go()
    //     // stakingContract.claimAllBSTRewards()
    // }, [])


    const getStakeOfAccount = async () => {
        try{ await stakingContract.stakeOf(account).then(
            (balance:number) => {
                const _balance = balance / 10 ** 18
                setStakedAmount(_balance.toString())
            })
        } catch(e){
            console.log(e)
        } 
    }

    const getBSTBalance = async () => {
        try{ await stakingContract.balanceOf(account).then(
            (balance:number) => {
                const _balance = balance / 10 ** 18
                setBalanceBST(_balance.toString())
            })
        } catch(e){
            console.log(e)
        } 
    }

    const getRewardOfAccount = async () => {
        try{ await stakingContract.BnUSDRewardOf(account).then(
            (rewards:number) => {
                const _rewards = rewards / 10 ** 18
                setRewards(_rewards.toString())
            })
        } catch(e){
            console.log(e)
        } 
    }

    const getClaimableBST = async () => {
        try{ await stakingContract.getClaimableBSTRewards(account).then(
            (rewards:number) => {
                rewards = parseInt(rewards.toString()) / 10 ** 18
                setClaimableBST(rewards)   
            })   
        } catch(e){
            console.log(e)
        } 
    }

    const getLockedBST = async () => {
        try{ 
            let res = await stakingContract.getLockedBSTRewards(account)
            setLockedBST(parseInt(res) / 10 ** 18)
        } catch(e){
            console.log(e)
        } 
    }

    useEffect(()=> {        
        const getVestingPeriod = async () => {
            try {
                const _res = await stakingContract.getCurrentPeriod()
                const _vestingPeriod = parseInt(_res)
                setVestingPeriod(_vestingPeriod.toString())
            } catch(e){
                console.log(e)
            }            
        }
        if(signer){
            getVestingPeriod()
        }
    // eslint-disable-next-line
    },[signer])

    const getTotalStaked = async () => {
        try{ 
            stakingContract.totalStakes().then(
            (totalStaked:number) => {
                const _totalStaked = totalStaked / 10 ** 18
                setTotalStaked(_totalStaked.toString())
            })
        }catch(e){
            console.log(e)
        } 
    }

    useEffect(() => {
        if(account !== ""){
            getTotalStaked()
        }
        // eslint-disable-next-line
    }, [account])

    useEffect(() => {
        if(account !== ""){
            getStakeOfAccount()
            getRewardOfAccount()
            getBSTBalance()
            getClaimableBST()
            getLockedBST()
        }
        // eslint-disable-next-line
    }, [account, isLoading])

    const claimBSTRewards = async () => {
        try {
            const tx = await stakingContract.claimFreeBSTRewards()
            setTransactionToCheck(tx)
            setIsLoading(true)
        } catch(e){
            console.log(e)
        }
    }

    const handleSelectReward = (event:any) => {
        setSelectedReward(event.target.value); 
        console.log(event.target.value)
    }
    
    const claimReward = async () => {
        // alert('claiming all rewards, paying a fee')
        // stakingContract.claimAllBSTRewards()
        // return
        
        if (selectedReward === "ICZ"){ 
            try { 
                const tx = await stakingContract.claimICZRewards({gasLimit: 400000})
                setTransactionToCheck(tx)
                setIsLoading(true)
            } catch(e){ 
                console.log(e)
            } 
        }

        else if (selectedReward === "BST"){
            try { 
                claimBSTRewards() 
            } catch(e){ 
                console.log(e)
            }
        }

        else {
            console.log('token not supported yet') 
        }
    }

    const handleSliderChange = (event:any) => {
        setSliderValue(event.target.value);
    }

    const handleEditStake = async () => {
        const _stakedAmount = parseInt(stakedAmount)  
        
        if (sliderValue === _stakedAmount){
            return
        }
        
        if (sliderValue > _stakedAmount){
            
            const _val = utils.parseEther((sliderValue - _stakedAmount).toString())
            // const _val = utils.parseEther((1000).toString())
            const bn = BigNumber.from(_val)
            
            try { 
                const tx = await stakingContract.createStake(bn, {gasLimit: 400000})
                setTransactionToCheck(tx)
                setIsLoading(true)
            } catch(e){ 
                console.log(e)
            }
        }
        else {
            const _val = utils.parseEther((_stakedAmount - sliderValue).toString())
            const bn = BigNumber.from(_val)
            try { 
                const tx = await stakingContract.removeStake(bn, {gasLimit: 400000})
                setTransactionToCheck(tx)
                setIsLoading(true)
                } catch(e){ 
                    console.log(e)
                }
        }
    }

    useEffect(() => {
        const getStakeOfAccount = async () => {
            try{ await stakingContract.stakeOf(account).then(
                (balance:number) => {
                    const _balance = balance / 10 ** 18
                    setSliderValue(_balance)
            })
            } catch(e){}
        }

        if(account !== ""){
            getStakeOfAccount()
        }
        // eslint-disable-next-line
    }, [account])

    return (
        <div className={openStakeMenu ? 'Staking StakingOpen' : 'Staking'} >
            <h2 
            className='StakingHeader'
            style={{textAlign:'center', alignSelf:'center', cursor:'pointer'}} onClick={()=>setOpenStakeMenu(!openStakeMenu)}>Blobble Finance</h2>

            <div style={{width:'90%',borderBottom:"1px solid #d1dffb", borderTop:"1px solid #d1dffb", display:"flex", flexDirection:"column", paddingTop: '14px', paddingBottom: '12px', textAlign:'left'}}>
                <h4 style={{marginTop:'0px',marginBottom: '0px'}}>BST:</h4>
                <div style={{gridArea:"avbst", justifySelf:"start"}}>Available: {Math.floor(parseInt(balanceBST))}</div>
                <div style={{ gridArea:"stbst", justifySelf:"start"}}>Staked: {Math.floor(parseInt(stakedAmount))}</div>
                <Slider
                    style = {{width: "90%", justifySelf:"center", alignSelf:"center"}}
                    value={sliderValue}
                    valueLabelDisplay="auto"
                    onChange={(event, value) => handleSliderChange(event)}
                    marks= {false}
                    min={0}
                    max={parseInt(balanceBST) + parseInt(stakedAmount)}
                />
                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-around'}}>
                    <LoadingButton
                        style={{marginTop: '20px', marginBottom: '2px', background:"#282c34", gridArea:"claimbutton", justifySelf:"center"}}
                        onClick={handleEditStake}
                        loading={loading}
                        variant="contained"
                        size="small"
                    >
                       edit stake
                    </LoadingButton>

                </div>
            
            </div>
            <div className='usersOverview'>
                <h4 style={{marginTop:'0px',marginBottom: '0px',gridArea:"rewardtitle", alignSelf:"center",justifySelf:"start"}}>
                    Rewards: 
                </h4>
                <div style={{gridArea:"vestingperiod", alignSelf:"center",justifySelf:"start"}}>
                    Lock Period: {vestingPeriod}
                </div>
                <div style={{gridArea:"bstreward", alignSelf:"center",justifySelf:"start"}}>
                    <div style={{width:"100%", justifySelf:"start", display:"flex"}}>
                        <div>BST Available: {claimableBST.toFixed(2)}</div>
                    
                        <div style={{marginLeft:"12px"}}>BST Locked: {lockedBST.toFixed(2)} </div>
                    </div>

                </div>
                <div style={{gridArea:"mintreward", justifySelf:"start"}}>
                    bnUSD Available: {parseFloat(rewards).toFixed(4)}
                </div>
                
                <FormControl variant="standard" sx={{ m: 1, width:'110px', margin:'auto'}} style={{ gridArea:"selecttoken", justifySelf:"start"}}>
                        <InputLabel 
                            id="select-token"
                            
                            >Token</InputLabel>
                        <Select 
                            style={{height: 'auto'}}
                            labelId="select-token-label"
                            size='small'
                            id="token-select"
                            value={selectedReward}
                            label="token"
                            onChange={handleSelectReward}
                            >
                            <MenuItem value={'ICZ'}>ICZ</MenuItem>
                            <MenuItem value={'BST'}>BST</MenuItem>
                        </Select>
                    </FormControl>
                
                
                <LoadingButton
                    style={{marginTop: '20px', marginBottom: '2px', background:"#282c34", gridArea:"claimbutton", justifySelf:"center"}}
                    onClick={claimReward}
                    loading={loading}
                    //loadingPosition="end"
                    variant="contained"
                    size="small"
                >
                    Claim
                </LoadingButton>
            </div>
                            
            <div style={{marginTop: '4px', marginBottom:'4px'}}>
                <p style={{margin:'2px'}}>Total Staked: {Math.floor(parseInt(totalStaked))} BST</p>
                {/* <p style={{margin:'2px'}}>Available: {Math.round(parseInt(amountOfBSTForSale))} BST</p> */}
            </div>
        </div>
    )
}

export default Staking;
