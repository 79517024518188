import React, { useEffect, useState } from 'react'
// import { useAccountContext, useBalanceContext, provider } from "../context/blobbolContext";
import { useAccountContext } from '../context/accountContext';
// eslint-disable-next-line
import { NFTCONTRACT_ADDR } from '../context/Constants';
import {ethers} from 'ethers'
import NFTabi from '../contracts/mainBlobble.json'
// eslint-disable-next-line
import AdopterAbi from '../contracts/adopter.json'
import BlobbolInstanceMarketOwner from './BlobbleInstanceMarketOwner';
import BlobbolInstanceMarketForSale from './BlobbleInstanceMarketForSale';
import Staking from './Staking';

const IdMetaDataMapping = new Map()
const blobblePriceMap = new Map()

function Market() {
    const { signer, account, blobbleBalance, setBlobbleBalance } = useAccountContext();
    const nftContract = new ethers.Contract(NFTCONTRACT_ADDR, NFTabi, signer!); 
    const [ totalSupply, setTotalSupply ] = useState<number>()
    const [ mapLength, setMapLength ] = useState<number>(0)

    // eslint-disable-next-line
    const [ mapTwoLength, setMapTwoLength ] = useState<number>(0)

    useEffect(()=>{
        const getTotalSupply = async () => {
            try { 
                let res = await nftContract.getAmountOfBlobbles()
                setTotalSupply(res)
            } catch(e){
                console.log(e)
            } 
        }

        if(signer){
            getTotalSupply()
        }
    
        // eslint-disable-next-line 
    },[signer]) 

    useEffect(()=>{
        const getBlobblesOnSale = async () => {
            for(let i:number = 0; i < totalSupply!; i++){
                let res = await nftContract.getIsForSale(i)
                if(res === true){
                    let _price = await nftContract.priceBlobble(i)
                    let _uri = await nftContract.tokenURI(i)
                    let _uri_decoded = decodeURIComponent(_uri!.replace(/\s+/g, '').replace(/[0-9a-f]{2}/g, '%$&')).substring(2);
                    blobblePriceMap.set(i, {"price":_price, "URI": _uri_decoded})
                }
            }

            setMapTwoLength(blobblePriceMap.size)
        }
        if(totalSupply){
            getBlobblesOnSale()
        }
    // eslint-disable-next-line 
    },[totalSupply, blobbleBalance])
    
    useEffect(()=>{
        const getAmountNftsOwnedByAccount = async () => {
            try{ await nftContract.balanceOf(account).then(
                (balance:number) => setBlobbleBalance(parseInt(balance.toString())))
            } catch(e){} 
        }
        getAmountNftsOwnedByAccount()
    // eslint-disable-next-line 
    },[account, mapLength])

 

    useEffect(() => {
        // call contract for every blobbol of account and map id to the metadata for every blobbol token
        const getNftIds = async () => {
            // first clear the map otherwise the list wont refresh on account switch (will just add the blobbol renders)
            IdMetaDataMapping.clear()
            for(let i = 0; i < blobbleBalance; i++){
                let _id: number;
                let _uri: string;
                
                // get nft id from contract by index (loops through all nfts using lenght of nftBalance)
                try{
                    await nftContract.tokenOfOwnerByIndex(account, i).then(
                    (id:string) => _id = parseInt(id.toString()))
                    // add nft id to mapping as key with temporary value
                    IdMetaDataMapping.set(_id!,"")
                } catch(e){ console.log('error getting nft id: ', i )}
                
                // use the retrieved nft-id to get the tokenuri (metadata) of the nft from the contract
                try{
                await nftContract.tokenURI(_id!).then((uri:string) => (_uri = uri))
                const _uri_decoded = decodeURIComponent(_uri!.replace(/\s+/g, '').replace(/[0-9a-f]{2}/g, '%$&')).substring(2);  
                IdMetaDataMapping.set(_id!,_uri_decoded)
            
                } catch(e){ console.log('error getting nft uri')}
                // I used this to cause an rerender of the renderlist below
            }
            setMapLength(IdMetaDataMapping.size)
     
        }
        if(blobbleBalance > 0){
            getNftIds()
        }
    // eslint-disable-next-line
    }, [blobbleBalance])

    const RenderBlobbles = () => {
        let nums = []
        let vals:string[] = [] 
        
        // eslint-disable-next-line        
        for (let [key, val] of IdMetaDataMapping) {
            nums.unshift(key as never)
            let _val = IdMetaDataMapping.get(key)
            vals.unshift(_val)
        }

        // THIS IS WHERE THE ACTUAL RENDERING OF THE BLOBBOLS HAPPENS
 
        if( IdMetaDataMapping.size > 0){
            return( 
            <>{ nums.map((id,index)=> ( 
                <BlobbolInstanceMarketOwner key={id} id={id} data={vals[index]}/> )
                )
            } 
            </> 
            ) 
        }
        else { return <> <div className="titleBlobbol">no blobbles found :(</div> </> }
    }

    const RenderBlobblesForSale = () => {
        let nums = []
        let vals:string[] = [] 
        
        // eslint-disable-next-line        
        for (let [key, val] of blobblePriceMap) {
            nums.unshift(key as never)
            let _val = blobblePriceMap.get(key)
            vals.unshift(_val) 
        }

        // THIS IS WHERE THE ACTUAL RENDERING OF THE BLOBBLES HAPPENs

        if( blobblePriceMap.size > 0){
            return( 
            <>{ nums.map((id,index)=> ( 
                <BlobbolInstanceMarketForSale key={"forsale" + id} id={id} price={vals[index]["price"]} uri={vals[index]["URI"]}/> )
                )
            } 
            </> 
            ) 
        }
        else { return <> <div className="titleBlobbol">no blobbles found :(</div> </> }
    }


    return (
    <div className='market'>
        <Staking/>

        <div className='titleBlobbol'>Your Blobbles</div>
        <div className='marketContainerOne'>
            {blobbleBalance > 0 ? <RenderBlobbles/> : <div className='titleBlobbol' style={{padding:'6px'}}>You don't own any Blobbles yet</div>}
        </div>
        <div className='titleBlobbol'>Blobble Market</div>
        <div className='marketInfo'>
            <p>
                <b>Important:</b><br></br>
                When you buy a Blobble make sure that you know the cost of getting it healthy again.
                Neglected Blobbles can be adopted by any one at any time!
            </p>
        </div>
        <div className='marketContainerTwo'>
            <RenderBlobblesForSale/>
        </div>
        {/* <button onClick={handleBuyClick}>buy</button> */}
    </div>
  )
}

export default Market