import React from 'react'
//import WalletConnectProvider from "@walletconnect/web3-provider";


class BlobbolGenes {
    colorMain = "#79018C";
    colorSec = "rgb(192, 70, 0)";
    colorThird = "rgb(128, 238, 95)";
    pupilColor = "";
    bodyWidth = "";
    bodyHeight = "";
    bodyRadiusOne = "";
    bodyRadiusTwo = "";
    bodyRadiusThree = "";
    bodyRadiusFour = "";
    bodyFillAWidth = "";
    bodyFillAHeight = "";
    bodyFillAY = "";
    bodyFillAX = "";
    bodyFillBWidth = "";
    bodyFillBHeight = "";
    bodyFillBY = "";
    bodyFillBX = "";
    leftEyePosX = "";
    leftEyePosY = "";
    rightEyePosX = "";
    rightEyePosY = "";
    mouthWidth = "";
    mouthHeight = "";
    mouthPosFromTop = "";
    mouthPosFromLeft =""
}

const blobbol: any = new BlobbolGenes()

export const useBlobbolInstance = (initial: any) => React.useState(initial)
export type UseBlobbolType = ReturnType<typeof useBlobbolInstance>
export type BlobbolType = UseBlobbolType[0]
export type SetBlobbolType = UseBlobbolType[1]

const BlobbolInstanceContext = React.createContext<UseBlobbolType | null>(null)

export const useBlobbolInstanceContext = () => React.useContext(BlobbolInstanceContext)!;

export const BlobbelInstanceContextProvider = ({children}: {children: React.ReactNode}) => (
    <BlobbolInstanceContext.Provider value={useBlobbolInstance(blobbol)}>
        {children}  
    </BlobbolInstanceContext.Provider>
)

export const useBlobbolGenesString = (initial: string) => React.useState(initial)
export type UseBlobbolGenesStringType = ReturnType<typeof useBlobbolGenesString>
export type BlobbolGenesStringType = UseBlobbolGenesStringType[0]
export type SetBlobbolGenesStringType = UseBlobbolGenesStringType[1]

const BlobbolGenesStringContext = React.createContext<UseBlobbolGenesStringType | null>(null)

export const useBlobbolGenesStringContext = () => React.useContext(BlobbolGenesStringContext)!;

export const BlobbolGenesStringContextProvider = ({children}: {children: React.ReactNode}) => (
    <BlobbolGenesStringContext.Provider value={useBlobbolGenesString("")}>
        {children}  
    </BlobbolGenesStringContext.Provider>
)

// export const useAccount = (initial: string) => React.useState(initial)
// export type UseAccountType = ReturnType<typeof useAccount>

// const AccountContext = React.createContext<UseAccountType | null>(null)

// export const useAccountContext = () => React.useContext(AccountContext)!;

// export const AccountContextProvider = ({children}: {children: React.ReactNode}) => (
//     <AccountContext.Provider value={useAccount("")}>
//         {children}  
//     </AccountContext.Provider>
// )

// export const useBalance = (initial: number) => React.useState(0)
// export type UseBalanceType = ReturnType<typeof useBalance> 
// const BalanceContext = React.createContext<UseBalanceType | null>(null)
// export const useBalanceContext = () => React.useContext(BalanceContext)!;

// export const BalanceContextProvider = ({children}: {children: React.ReactNode}) => (    
//     <BalanceContext.Provider value={useBalance(0)}>
//         {children}  
//     </BalanceContext.Provider>
// )

// export const provider = new WalletConnectProvider({
//     rpc: {
//       97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
//       // ...
//     },
//   });

declare const window: any;
export const provider = window.hanaWallet?.ethereum