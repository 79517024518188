import React, {useState, useEffect} from 'react'
import { ethers } from 'ethers';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useAccountContext } from '../../context/accountContext';

// type Props = {
// }

const sleep = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

const LoadingStateModal = () => {
    const { isLoading, setIsLoading, transactionToCheck, setTransactionToCheck, getAllowance } = useAccountContext();
    const [countdown, setCountdown] = useState(30)
    const [status, setStatus] = useState("Waiting for transaction to be mined")
    const [dots, setDots] = useState(".")
    const [hash, setHash] = useState("")

    const handleClose = () => {
        setIsLoading(false);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if(countdown > 0) {
                setCountdown(countdown - 1)
            }
        }, 1000)
        return () => clearInterval(interval)
    }, [countdown])

    useEffect(() => {
        setCountdown(25)
    }, [transactionToCheck])

    useEffect(() => {
        const checkTx = async () => {
            transactionToCheck!.wait().then((receipt: ethers.providers.TransactionReceipt) => {
                if(receipt.status === 1) {
                    getAllowance()
                    setStatus("✅ Transaction successful! Closing in 5 seconds")
                    setCountdown(5)
                    sleep(5000).then(() => {
                        setIsLoading(false)
                    })

                    setTransactionToCheck(null)  
                } else {
                    alert("Something went wrong. Check the Snow Tracker.")
                }
                // setIsLoading(false)
            })
            setTransactionToCheck(null)
        }
        if(transactionToCheck) {
            checkTx()
        }
        // eslint-disable-next-line
    }, [isLoading, getAllowance, setStatus, setCountdown, setIsLoading, setTransactionToCheck])

    useEffect(() => {
        // function that adds a dot every 300ms up to three dots and then resets to one dot
        const interval = setInterval(() => {
            if(dots.length < 3) {
                setDots(dots + ".")
            } else {
                setDots("")
            }
        }, 500)
        return () => clearInterval(interval)
    }, [dots])

    useEffect(() => {
        if(transactionToCheck) {
            setHash(transactionToCheck.hash)
        }

    }, [transactionToCheck])

    return (
    <div>                    
        <Dialog 
        open={isLoading} 
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        >
        <DialogTitle>Please Hold...</DialogTitle>
        <DialogContent>
            <DialogContentText style={{whiteSpace: 'pre-line'}}>
                <span>
                Transaction: {hash} <br/>
                Status: {status} {dots}<br/>
                Estimated seconds remaining: {countdown}
                </span>
            </DialogContentText>
        </DialogContent>

        {/* <DialogActions>
            <Button onClick={handleClose}>Close</Button>
        </DialogActions> */}
        </Dialog>
    </div>
  )
}

export default LoadingStateModal