export const CYCLESIZE = 60
export const CYCLEPRICE = 1

const adopterContract = "0x976C214741b4657bd99DFD38a5c0E3ac5C99D903"
const stakingContract = "0x942ED2fa862887Dc698682cc6a86355324F0f01e"
const scoresContract = "0x8bEe2037448F096900Fd9affc427d38aE6CC0350"
const MainContract = "0x8D81A3DCd17030cD5F23Ac7370e4Efb10D2b3cA4"

export const ADOPTER_CONTRACT_ADDR: string = adopterContract
export const STAKING_CONTRACT_ADDR: string = stakingContract
export const SCORESCONTRACT_ADDR: string = scoresContract
export const NFTCONTRACT_ADDR: string = MainContract
export const BNUSD_ADDR = "0x6da56a296292535E4c03b9c61e0Cb7019303db28"


