import React, {useEffect} from 'react'
import { utils } from 'ethers'
import { useAccountContext } from '../context/accountContext';
import BasicMenu from './BasicMenu';
import ModalAllowance from './modals/ModalAllowance';

function Navbar() {
  const [openAllowanceModal, setOpenAllowanceModal] = React.useState(false);
  const {account, signer, balance, setBalance, blobbleBalance, allowance} = useAccountContext() 
  
  useEffect(() => {
    const _getBalance = async() => {
      const balance = await signer!.getBalance();
      setBalance(parseInt(utils.formatEther(balance)));
    }
   
    if (account !== '') {
      try{
        _getBalance()
      } catch(e){
        console.log(e)
      }
    }
    // eslint-disable-next-line
  }, [account])

  return (
    <div>
      <div className="Navbar">
        { account && <div className="navItem">{account.substring(0,4)+"..."+account.slice(-4) }</div> }
        { <div className="navItem">{Math.floor(balance)}  {" ICZ"}</div> }
        { 
          <div className="navItem"
          onClick={() => setOpenAllowanceModal(true)}
          > 
            {" bnusd: "} {"$"} {Math.floor(allowance)} 
          </div>
        }
        {blobbleBalance === 0 ? <div className="navItem">{blobbleBalance} Blobbles :(</div> :
          <>
          {blobbleBalance === 1 ? <div className="navItem">{blobbleBalance} Blobble!</div> :
          <div className="navItem">{blobbleBalance} Blobbles!</div>}
          </>
        }
        <div style={{position:"fixed",right:"0px"}}>

          <BasicMenu/>
          
        </div>

      </div> 
      <ModalAllowance openAllowanceModal={openAllowanceModal} setOpenAllowanceModal={setOpenAllowanceModal}/>
    </div>
  )
}

export default Navbar
