import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { ethers } from 'ethers'
import NFTabi from '../../contracts/mainBlobble.json'
// eslint-disable-next-line
import { NFTCONTRACT_ADDR } from '../../context/Constants'
import ReactHtmlParser from 'react-html-parser'
import { useAccountContext } from '../../context/accountContext';


type BlobbolInstanceProps = {
    id: number | null
    data: string | null
    score: number | null
}

export default function FormDialog(props:BlobbolInstanceProps) {
  const [open, setOpen] = React.useState(false);
  const {signer } = useAccountContext();

  // const [ loading, setLoading ] = React.useState(false);
  const [ price, setPrice ] = React.useState(0)

  
  const nftContractSigner = new ethers.Contract(NFTCONTRACT_ADDR, NFTabi, signer!);  

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


// function to check tx
// const checkTx = async (tx:string) => {
//     let res = await PROVIDER.waitForTransaction(tx)
//     let status = res.status
//     if(status === 1){
//         setLoading(false)
//     } else {
//         alert("Something went wrong with the transaction.")
//         setLoading(false)
//     }
// }

const handleClickSetForSale = async () => {
    // setLoading(true)
    const _value = ethers.utils.parseEther(price.toString())
    try { 
        let tx = await nftContractSigner.setForSale(props.id, _value) 
        // checkTx(tx.hash)
        console.log(tx)
    } catch(e){ 
        console.log(e)
        // setLoading(false)
    } 
}

const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setPrice(parseInt(e.target.value))
}

const checkIsforSale = (_id:number) => {
  const el = document.querySelectorAll<HTMLElement>(`#Blobble_MarketForSale${_id.toString()}`)

  if(el.length === 0){
      return false
  }
  else {
      return true
  }
}

  return (
    <div>
                            
      <Button size="small" onClick={handleClickOpen}style={{marginTop: '20px', marginBottom: '2px', background:"#282c34", gridArea:"claimbutton", justifySelf:"center", color:"white"}} >
        {checkIsforSale(props.id!) ? "edit price" : "set for sale"}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Set Blobble #{props.id} for sale</DialogTitle>
        <DialogContent >
          <DialogContentText>
            Make sure your Blobble is healthy and happy. Otherwise it will be up for adoption!
          </DialogContentText>
          <div className="modalSetForSale">
          <div style={{paddingTop: "15px"}}>
            <div>score: {props.score}</div>

            <TextField
                autoFocus
                margin="dense"
                id="name"
                label="price in bnUSD"
                type="number"
                variant="standard"
                onChange={(e:React.ChangeEvent<HTMLInputElement>)=>{handleChange(e)}}
            />

          </div>
          {props.data && 
            <>
                <div className="blobbolCharacter">{ReactHtmlParser(props.data)}</div>
            </>
          }
  
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClickSetForSale}>{"Set For Sale!"}</Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}
