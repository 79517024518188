import React from 'react'
import {  utils, ethers } from 'ethers'
import { useBlobbolGenesStringContext } from "../context/blobbolContext";
import { useAccountContext } from '../context/accountContext';
import nftABI from '../contracts/mainBlobble.json'
import { NFTCONTRACT_ADDR } from '../context/Constants';


function MintBlobbol() {
    const {signer, account, allowance, setTransactionToCheck, setIsLoading} = useAccountContext()
    const nftContract = new ethers.Contract(NFTCONTRACT_ADDR, nftABI, signer!)
    const [ blobbolGenesString ] = useBlobbolGenesStringContext()

    const mintIt = async() => {
        if (allowance < 1) {
            alert("You need to approve the contract to spend your BNUSD")
            return
        }
        
        const blobhex: string = utils.hexlify(utils.toUtf8Bytes(blobbolGenesString))
        let options = {
            gasLimit : 9900000,
        }
  
        const res = await nftContract.safeMint(account, blobhex, options)  
        setTransactionToCheck(res)
        setIsLoading(true)

    }
   
    return (
        <div>     
            <button 
            className="buttonNormal" 
            onClick={mintIt}
            // disabled={allowance < 1}
            >
                Mint This Blobble!
            </button>
        </div>
    )
}

export default MintBlobbol
