import React, { useState,useEffect }from 'react'
import {ethers} from 'ethers'
import { useAccountContext } from "../context/accountContext";
import nftABI from '../contracts/mainBlobble.json'
import scoresABI from '../contracts/scoresContract.json'
import BlobbleOrphanInstance from './BlobbleOrphanInstance';
import { NFTCONTRACT_ADDR, ADOPTER_CONTRACT_ADDR, SCORESCONTRACT_ADDR } from '../context/Constants';
//import adopterContractABI from '../contracts/adopter.json'
// import { provider } from "../context/blobbolContext";

const IdMetaDataMapping = new Map()

// because of using a map have to force a rerender of component...
// atm need 1 more rerender than is happening...


function Orphanage() {
    const {provider, signer, account} = useAccountContext()

    // const PROVIDER = new ethers.providers.Web3Provider(provider)
    // const SIGNER = PROVIDER.getSigner()
    const nftContract = new ethers.Contract(NFTCONTRACT_ADDR, nftABI, signer!);  
    //const adopterContract = new ethers.Contract(ADOPTER_CONTRACT_ADDR, adopterContractABI, PROVIDER)
    const callContract = new ethers.Contract(NFTCONTRACT_ADDR, nftABI, provider!)
    const [isApproved, setIsApproved] = useState(false)
    const scoresContract = new ethers.Contract(SCORESCONTRACT_ADDR, scoresABI, provider!)
    
    // eslint-disable-next-line
    const [ mapLength, setMapLength ] = useState<number>(0)
    const [totalAmountOfBlobbles, setTotalAmountOfBlobbles] = useState<number>(0)

    useEffect(() => {
        const getTotalAmountOfBlobbles = async() => {
            await nftContract.getAmountOfBlobbles().then((res: number) => 
                setTotalAmountOfBlobbles(parseInt(res.toString())))
        }
        getTotalAmountOfBlobbles()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const getNeglectedBlobbles = async () => {
            for (let id = 0; id < totalAmountOfBlobbles; id++) {
                let _x :string;
                let _uri: string;
                let _score: number;
 
                await scoresContract.scoreIndex(id).then((res:any) => {
                    // a none existing blobble will return a 0,0,0 string
                    _x = res.toString()
                    if(_x !== "0,0,0") {
                        _score = (res[0]/10 + res[1]/10 + res[2]/10)
                    }
                })
                try{  
                    await nftContract.tokenURI(id).then((res:any) => {
                        _uri = res.toString()
                        const _uri_decoded = decodeURIComponent(_uri.replace(/\s+/g, '').replace(/[0-9a-f]{2}/g, '%$&')).substring(2);  
                        
                        let _treshold: number = 0
                        if (_score < _treshold) {
                            IdMetaDataMapping.set(id, _uri_decoded)
                        }
                        
                        setMapLength(IdMetaDataMapping.size)
                    })
                } catch(e){console.log(e)} 
            } // end of getting all neglected blobbles id's
        } 

        try { getNeglectedBlobbles()} catch(e) {console.log(e) }
        // force rerender the last Blobble (needed because mapping updating does not cause a rerender)
        setMapLength(IdMetaDataMapping.size)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapLength, totalAmountOfBlobbles])
   
    useEffect(() => {
        
        const getIsApproved = async () => {
            await callContract.isApprovedForAll(account, ADOPTER_CONTRACT_ADDR).then((res:boolean) => setIsApproved(res))
        }
        if (account) {
            getIsApproved()
        }
       
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account])

    const setApproval = () => {
        nftContract.setApprovalForAll(ADOPTER_CONTRACT_ADDR,true).then((res:any) => setIsApproved(res))
    }
    
    const RenderBlobbles = () => {
        
        let nums = []
        let vals:string[] = [] 
        // eslint-disable-next-line        
        for (let [key, val] of IdMetaDataMapping) {
            nums.unshift(key as never)
            let _val = IdMetaDataMapping.get(key)
            
            vals.unshift(_val)
        }
        // console.log('map size: ',IdMetaDataMapping.size)
        //////////////////////////////////////////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////////////////////////////////////
        // THIS IS WHERE THE ACTUAL RENDERING OF THE BLOBBOLS HAPPENS
        
        if( IdMetaDataMapping.size > 0){
            return( 
            <>{ nums.map((id,index)=> ( 
                <BlobbleOrphanInstance key={id} id={id} data={vals[index]}/> )
                )
            } 
            </> 
            ) 
        }
        else { return <> <div className="titleBlobbol">no blobbols found :(</div> </> }
       
    }


    return (
        <div className="Orphanage">
            <div className="Orphanage-header">
                <h1>Blobble Orphanage</h1>

            </div>
            
                { isApproved === true ? 
                    <div className='orphanageWrapper'>
                        <RenderBlobbles/>
                    </div>
                : <div
                    style={{
                        width: '40%',
                        margin: 'auto',
                        borderRadius:"5px",
                        padding:"20px",
                        display:"flex",
                        justifyContent:"center",
                        alignItems:"center",
                        flexDirection:"column",
                        justifySelf:"center",
                        background:"white"}}>
                    <div className="titleBlobbol">You need to approve the Orphanage before you can enter</div>
                    <button className="buttonNormal" onClick={setApproval}>Approve Orphanage</button> 
                    </div>
                }
            
        </div>
    )
}

export default Orphanage
