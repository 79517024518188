
import React from 'react';
import { useBlobbolInstanceContext, useBlobbolGenesStringContext } from "../context/blobbolContext";
import { useAccountContext } from '../context/accountContext';
import '../Blobbol.css'
import MintBlobbol from './MintBlobbol';

class BlobbolGenes {
    colorMainHsl= "";
    colorMain = "#79018C";
    colorSec = "rgb(192, 70, 0)";
    colorThird = "rgb(128, 238, 95)";
    pupilColor = "";
    bodyWidth = "";
    bodyHeight = "";
    bodyRadiusOne = "";
    bodyRadiusTwo = "";
    bodyRadiusThree = "";
    bodyRadiusFour = "";
    bodyFillAWidth = "";
    bodyFillAHeight = "";
    bodyFillAY = "";
    bodyFillAX = "";
    bodyFillBWidth = "";
    bodyFillBHeight = "";
    bodyFillBY = "";
    bodyFillBX = "";
    pupilsSide = "";
    pupilsMovement = "";
    leftEyePosX = "";
    leftEyePosY = "";
    rightEyePosX = "";
    rightEyePosY = "";
    mouthWidth = "";
    mouthHeight = "";
    mouthPosFromTop = "";
    mouthPosFromLeft =""
}

const randomizeColor = () => {
    return Math.floor(Math.random()*16777215).toString(16);
}

const randomizeHslColor = (h_min:number,h_max:number, l_min:number, l_max:number) :string => {
    return `hsl(${Math.floor(Math.random()* (h_max - h_min)+ h_min).toString()},100%,
    ${Math.floor(Math.random()* (l_max - l_min)+ l_min).toString()}%)`
}

const getRandomNumber = (min: number, max: number) => {
    return Math.random() * (max - min) + min;
}

const randomizePupils = (mov: string, side: string) => {
    if (mov === "movement"){
        let pupilmovement = ['moveupdown', 'moveleftright', 'moveround']
        return pupilmovement[Math.floor(Math.random() * pupilmovement.length)];
    }
    if(side === "side"){
        let pupilside = ['5', '0', '7', '-5']
        return pupilside[Math.floor(Math.random()*pupilside.length)]
    } else return ""
}

// for the pupils
function getRandomDarkColor() {
    return 'rgb(' + 
      (Math.floor(Math.random()*56)+getRandomNumber(2,190)) + ', ' +
      (Math.floor(Math.random()*56)+getRandomNumber(2,170)) + ', ' +
      (Math.floor(Math.random()*56)+getRandomNumber(2,170)) + ')' 
    ;
}
export const blobbol = new BlobbolGenes()


// generate a random blobbol object and show on site
const generateBlobbol = () => {
    //first generate random values and set them to the above created blobbol instance
    //then use the values of that instance to set it to the to blobbel on the screen



    // random values to instance
    blobbol.colorMainHsl = randomizeHslColor(1,100,10,100)
    //blobbol.colorMain = '#'+randomizeColor()
    blobbol.colorSec = '#'+randomizeColor()
    blobbol.colorThird = '#'+randomizeColor()
    blobbol.pupilColor = getRandomDarkColor()
    // values of instance to screen
    document.documentElement.style.setProperty('--color-main-hsl', blobbol.colorMainHsl);
    //document.documentElement.style.setProperty('--color-main', blobbol.colorMain);
    document.documentElement.style.setProperty('--color-sec', blobbol.colorSec);
    document.documentElement.style.setProperty('--color-third', blobbol.colorThird);
    document.documentElement.style.setProperty('--pupil-color', blobbol.pupilColor);
    // random values to instance
    blobbol.bodyWidth = getRandomNumber(135,150)+"px"
    blobbol.bodyHeight = getRandomNumber(135,150)+"px"
    // values of instance to screen
    document.documentElement.style.setProperty('--body-width', blobbol.bodyWidth);
    document.documentElement.style.setProperty('--body-height', blobbol.bodyHeight);
    // random values to instance 
    blobbol.bodyRadiusOne = getRandomNumber(30,60)+"%"
    blobbol.bodyRadiusTwo = getRandomNumber(30,60)+"%"
    blobbol.bodyRadiusThree = getRandomNumber(30,35)+"%"
    blobbol.bodyRadiusFour = getRandomNumber(16,30)+"%"
    // values of instance to screen
    document.documentElement.style.setProperty('--body-radius-one', blobbol.bodyRadiusOne);
    document.documentElement.style.setProperty('--body-radius-two', blobbol.bodyRadiusTwo);
    document.documentElement.style.setProperty('--body-radius-three', blobbol.bodyRadiusThree);
    document.documentElement.style.setProperty('--body-radius-four', blobbol.bodyRadiusFour);
    // random values to instance
    blobbol.bodyFillAWidth = getRandomNumber(70,90)+"%"
    blobbol.bodyFillAHeight = getRandomNumber(70,90)+"%"
    blobbol.bodyFillAY = getRandomNumber(3,45)+"%"
    blobbol.bodyFillAX = getRandomNumber(40,80)+"%"
    // values of instance to screen
    document.documentElement.style.setProperty('--body-fill-a-width', blobbol.bodyFillAWidth);
    document.documentElement.style.setProperty('--body-fill-a-height', blobbol.bodyFillAHeight);
    document.documentElement.style.setProperty('--body-fill-a-y', blobbol.bodyFillAY);
    document.documentElement.style.setProperty('--body-fill-a-x', blobbol.bodyFillAX);
    // random values to instance
    blobbol.bodyFillBWidth = getRandomNumber(60,80)+"%"
    blobbol.bodyFillBHeight = getRandomNumber(60,80)+"%"
    blobbol.bodyFillBY = getRandomNumber(2,90)+"%"
    blobbol.bodyFillBX = getRandomNumber(1,50)+"%"
    // values of instance to screen
    document.documentElement.style.setProperty('--body-fill-b-width', blobbol.bodyFillBWidth);
    document.documentElement.style.setProperty('--body-fill-b-height', blobbol.bodyFillBHeight);
    document.documentElement.style.setProperty('--body-fill-b-y', blobbol.bodyFillBY);
    document.documentElement.style.setProperty('--body-fill-b-x', blobbol.bodyFillBX);
    // random values to instance
    blobbol.leftEyePosX = getRandomNumber(10,17)+"%"
    blobbol.leftEyePosY = getRandomNumber(9,13)+"%"
    // values of instance to screen
    document.documentElement.style.setProperty('--left-eye-position-x', blobbol.leftEyePosX);
    document.documentElement.style.setProperty('--left-eye-position-y', blobbol.leftEyePosY);
    // random values to instance
    blobbol.rightEyePosX = getRandomNumber(10,17)+"%"
    blobbol.rightEyePosY = getRandomNumber(9,13)+"%"
    // values of instance to screen
    document.documentElement.style.setProperty('--right-eye-position-x', blobbol.rightEyePosX);
    document.documentElement.style.setProperty('--right-eye-position-y', blobbol.rightEyePosY);
    // random values to instance
    blobbol.pupilsMovement = randomizePupils('movement', '')
    blobbol.pupilsSide = randomizePupils("", "side")+"px"
    // values of instance to screen
    document.documentElement.style.setProperty('--pupil-animate', blobbol.pupilsMovement);
    document.documentElement.style.setProperty('--pupils-side', blobbol.pupilsSide);
    if (blobbol.pupilsMovement === "moveround"){
        document.documentElement.style.setProperty('--pupil-animate-timing', "linear");
    }
    // random values to instance
    blobbol.mouthWidth = getRandomNumber(45,51)+"px"
    blobbol.mouthHeight = getRandomNumber(20,26)+"px"
    blobbol.mouthPosFromTop = getRandomNumber(34,38)+"%"
    blobbol.mouthPosFromLeft = getRandomNumber(20,40)+"%"
    // values of instance to screen
    document.documentElement.style.setProperty('--mouth-width',blobbol.mouthWidth  );
    document.documentElement.style.setProperty('--mouth-height', blobbol.mouthHeight );
    document.documentElement.style.setProperty('--mouth-pos-from-top', blobbol.mouthPosFromTop);
    document.documentElement.style.setProperty('--mouth-pos-from-left', blobbol.mouthPosFromLeft );
} 

const stylesToCatch = ['margin','width','height','background-color','border-radius','position', 'overflow','top','left','right', 'display', 'animation-name'
    ,'animation-duration', 'animation-iteration-count', 'animation-timing-function',
   'transition-property', 'transition-duration', 'transition-timing-function',]

declare const window: any

// blobbal
function Blobbol() {
    // eslint-disable-next-line
    const [ blobbolInstance, setBlobbelInstance ] = useBlobbolInstanceContext()
    // eslint-disable-next-line
    const [ blobbolGenesString, setBlobbolGenesString ] = useBlobbolGenesStringContext()
    const {account} = useAccountContext()

    const characterRef = React.useRef<HTMLDivElement>(null);
    const bodyFillARef = React.useRef<HTMLDivElement>(null);
    const bodyFillBRef = React.useRef<HTMLDivElement>(null);
    const leftEyeRef = React.useRef<HTMLDivElement>(null);
    const rightEyeRef = React.useRef<HTMLDivElement>(null);
    const pupilRef = React.useRef<HTMLDivElement>(null);
    const mouthRef = React.useRef<HTMLDivElement>(null);

    const divOpen = "<div style="

    // get the blobbol style properties
    const copyStylesToGlobalGenesString = async () => {
        // set a timer
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        let main_str = ""
        
        ////////////////////////////////////////////////////////////////////////
        // first get the styles of the parent div and add them to main_str
        
        let s = window.getComputedStyle(characterRef.current)
        let temp_arr = []
        for(let i = 0; i < stylesToCatch.length; i++){
            let c = stylesToCatch[i]
            if (stylesToCatch[i] === 'animation-name' ||
                stylesToCatch[i] === 'animation-duration' ||
                stylesToCatch[i] ==='animation-iteration-count'||
                stylesToCatch[i] ==='animation-timing-function'
            ) { continue }            
            if (stylesToCatch[i] === 'border') {
                continue
            } 
            else {
                temp_arr.push(stylesToCatch[i] + ":" + s[c] + ";" as never)
            }
        }
        // join strings together for the main rebuild array
        main_str = divOpen + temp_arr.join("").replace(/ /g,'') 
            + "border:15px;border-style:solid; id=\"body_main\">"
        
        ///////////////////////////////////////////////////////////////////////////////////
        // get the styles of the 1st child div (body fill a) and add them to main_str
        s = window.getComputedStyle(bodyFillARef.current)
        temp_arr = []
        
        for(let i = 0; i < stylesToCatch.length; i++){
            let c = stylesToCatch[i]
            if (stylesToCatch[i] === 'animation-name' ||
            stylesToCatch[i] === 'animation-duration' ||
            stylesToCatch[i] ==='animation-iteration-count'||
            stylesToCatch[i] ==='animation-timing-function')
            { continue }
            else {
                temp_arr.push(stylesToCatch[i] + ":" + s[c] +";" as never)
            }
        }

        // join strings together for the main rebuild array 
        main_str = main_str + divOpen + temp_arr.join("").replace(/ /g,'')+" id=\"body_a\"</div></div>"

        ///////////////////////////////////////////////////////////////////////////////////
        // get the styles of the 2nd child div (body fill b) and add them to main_str
        s = window.getComputedStyle(bodyFillBRef.current)
        temp_arr = []
        
        for(let i = 0; i < stylesToCatch.length; i++){
            let c = stylesToCatch[i]
            if (stylesToCatch[i] === 'animation-name' ||
                stylesToCatch[i] === 'animation-duration' ||
                stylesToCatch[i] ==='animation-iteration-count'||
                stylesToCatch[i] ==='animation-timing-function')
            { continue }
            else {
                temp_arr.push(stylesToCatch[i] + ":" + s[c] +";" as never)
            }
        }

        // join strings together for the main rebuild array 
        main_str = main_str + divOpen + temp_arr.join("").replace(/ /g,'')+ " id=\"body_b\" </div></div>"
        
        ///////////////////////////////////////////////////////////////////////////////////
        // get the styles of the left eye div and add them to main_str
        s = window.getComputedStyle(leftEyeRef.current)
        temp_arr = []
        
        for(let i = 0; i < stylesToCatch.length; i++){
            let c = stylesToCatch[i]
            if (stylesToCatch[i] === 'animation-name' ||
                stylesToCatch[i] === 'animation-duration' ||
                stylesToCatch[i] ==='animation-iteration-count'||
                stylesToCatch[i] ==='animation-timing-function')
            { continue }
            else {
                temp_arr.push(stylesToCatch[i] + ":" + s[c] +";" as never)
            }
        }

        // join strings together for the main rebuild array , 1 less close div to add pupil
        main_str = main_str + divOpen + temp_arr.join("").replace(/ /g,'')+ " id=\"eye_left\"</div>"
        /////////////////////////////////
        //add a pupil div to the left eye
        s = window.getComputedStyle(pupilRef.current)
        temp_arr = []
        
        for(let i = 0; i < stylesToCatch.length; i++){
            let c = stylesToCatch[i]
            temp_arr.push(stylesToCatch[i] + ":" + s[c] +";" as never) 
        }
        // join strings together for the main rebuild array , 1 extra close parent div as well
        main_str = main_str + divOpen + temp_arr.join("").replace(/ /g,'')+" id=\"pupil_left\"</div></div></div>"


        ///////////////////////////////////////////////////////////////////////////////////
        // get the styles of the right eye div and add them to main_str
        s = window.getComputedStyle(rightEyeRef.current)
        temp_arr = []
        
        for(let i = 0; i < stylesToCatch.length; i++){
            let c = stylesToCatch[i]
            if (stylesToCatch[i] === 'animation-name' ||
                stylesToCatch[i] === 'animation-duration' ||
                stylesToCatch[i] ==='animation-iteration-count'||
                stylesToCatch[i] ==='animation-timing-function')
                { continue }
            else {
                temp_arr.push(stylesToCatch[i] + ":" + s[c] +";" as never)
            }
        }

        // join strings together for the main rebuild array , 1 less close div to add pupil
        main_str = main_str + divOpen + temp_arr.join("").replace(/ /g,'')+" id=\"eye_right\"</div>"
        /////////////////////////////////
        //add a pupil div to the right eye
        s = window.getComputedStyle(pupilRef.current)
        temp_arr = []
        
        for(let i = 0; i < stylesToCatch.length; i++){
            let c = stylesToCatch[i]
            temp_arr.push(stylesToCatch[i] + ":" + s[c] +";" as never) 
        }
        // join strings together for the main rebuild array , 1 extra close parent div as well
        main_str = main_str + divOpen + temp_arr.join("").replace(/ /g,'')+" id=\"pupil_right\"</div></div></div>"

        ///////////////////////////////////////////////////////////////////////////////////
        // get the styles of the mouth div  and add them to main_str
        s = window.getComputedStyle(mouthRef.current)
        temp_arr = []
        
        for(let i = 0; i < stylesToCatch.length; i++){
            let c = stylesToCatch[i]
            if (stylesToCatch[i] === 'animation') { continue }
            if (stylesToCatch[i] === 'border-radius') {
                temp_arr.push(("border-radius:" + s[c]+ ";").replace(/ /g,'') as never)
            } else {
                temp_arr.push(stylesToCatch[i] + ":" + s[c] +";" as never)
            }
        }

        // join strings together for the main rebuild array 
        main_str = main_str + divOpen + temp_arr.join("").replace(/ /g,'')+" id=\"mouth\"</div></div>"
        // finally set the global 'blobbol genes string' state
        setBlobbolGenesString(main_str)
    }
    

    // generate blobbol on first loading the page (or refresh)
    React.useEffect(() => {
        generateBlobbol()
        setBlobbelInstance(blobbol)
        copyStylesToGlobalGenesString()
    // eslint-disable-next-line
    }, [])



    return (
        <div className="MainContainer">
            <div className="BlobbolView">
                
                <div className="character" ref={characterRef}>
                    <div className="body-fill-a" ref={bodyFillARef}></div>
                    <div className="body-fill-b" ref={bodyFillBRef}></div>
                    <div className="left-eye" ref={leftEyeRef}><div className='pupil' ref={pupilRef}></div></div>
                    <div className="right-eye" ref={rightEyeRef}><div className='pupil' ref={pupilRef}></div></div>
                    <div className="mouth" ref={mouthRef}></div>
                </div>
                
                <div className="buttonContainer">
                    <button className="buttonNormal"
                        onClick={()=>{generateBlobbol(); 
                        setBlobbelInstance(blobbol); 
                        copyStylesToGlobalGenesString()}}>Generate a new Blobble</button>
                </div>
                
                {account && <MintBlobbol/>}
                {/* <MintBlobbol/> */}
            </div> 
        </div>
    )
}

export default Blobbol
