import React, {useState, useEffect} from 'react'
import { NFTCONTRACT_ADDR, SCORESCONTRACT_ADDR } from '../context/Constants';
import { ethers } from 'ethers'
import NFTabi from '../contracts/mainBlobble.json'
import scoreAbi from '../contracts/scoresContract.json'
// import smallPlus from '../icons/plus_small.svg';
import { useAccountContext } from '../context/accountContext';
import {FaLock, FaPlus} from 'react-icons/fa'

const green = '#125C13'
const orange = 'orange'
const red = 'red'

// the score is actualy the amount of px thats the statbar is pushed from the right
const returnColor = (score:number) => {
    if (score < 60 && score > 1) {
        return green
    } else if (score > 59 && score < 75) {
        return orange
    } else if (score > 74) {
        return red
    }
}

// keep in mind: food, drink and love are processed by invertedPercentage()
interface BlobInstance {
    id: number
    age: number
    food: number | null
    drink: number | null
    love: number | null
    menustate: boolean
}

function Stats(props:BlobInstance) {
    const {signer, setIsLoading, setTransactionToCheck } = useAccountContext()
    const signerContract = new ethers.Contract(NFTCONTRACT_ADDR, NFTabi, signer!)
    const scoreContract = new ethers.Contract(SCORESCONTRACT_ADDR, scoreAbi, signer!)
    const [foodLock, setFoodLock] = useState<boolean>(false)
    const [drinkLock, setDrinkLock] = useState<boolean>(false)
    const [loveLock, setLoveLock] = useState<boolean>(false)
    
    const check = 0

    // 0 food, 1 drink and 2 love
    const addToScore = async (index:number) => {
        const options = {
            gasLimit: 1000000
        }

        const tx = await signerContract.addToBalanceManually(props.id,index,check, options)
        setTransactionToCheck(tx)
        setIsLoading(true)

        switch (index) {
            case 0:
                setFoodLock(false)
                break;
            case 1:
                setDrinkLock(false)
                break;
            case 2:
                setLoveLock(false)
                break;
            default:
                break;
        }
    }
    
    const getLockStatus = async (_type:number) => {
        try {
            const res = await scoreContract.checkLock(props.id,_type)
            return res
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        const _getLockStatus = async () => {
            const food = await getLockStatus(0)
            const drink = await getLockStatus(1)
            const love = await getLockStatus(2)
            
            setFoodLock(food)
            setDrinkLock(drink)
            setLoveLock(love)
        }
        _getLockStatus()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.id])

    return (
  
    <div className= "parentStats" >
        <div className= "stats" >
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <div className="statBarContainer"> 
                    <div id="food" className="statBar" 
                        style={{width: 100 - props.food! + "%", backgroundColor: returnColor(props.food!)}}>
                        {/* <div style={{position:"absolute", left:"50%"}}> {(0-props.food!).toString()}</div>    */}
                    </div> 
                </div>
                <div className="plusButton" onClick={foodLock ? ()=>addToScore(0) : ()=>{}}>
                    {foodLock === false ? <FaLock/> : <FaPlus/>} 
                </div>
            </div>
        </div>
        <div className="stats" >
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>   
                <div className="statBarContainer"> 
                    <div id="drinks" className="statBar" 
                        style={{width: 100 - props.drink! + "%", backgroundColor: returnColor(props.drink!)}}>
                        {/* <div style={{position:"absolute", left:"50%"}}> {(0-props.drink!).toString()}</div>    */}
                    </div> 
                </div>
                <div className="plusButton" onClick={drinkLock ? ()=>addToScore(1) : ()=>{}}>
                    {drinkLock === false ? <FaLock/> : <FaPlus/>}
                </div>
            </div>
        </div>
        <div className="stats" >
            <div style={{display: "flex", 
                    justifyContent: "center", 
                    alignItems: "center"}}>   
                <div className="statBarContainer"> 
                    <div id="love" className="statBar" 
                        style={{width: 100 - props.love! + "%", backgroundColor: returnColor(props.love!)}}>
                        {/* <div style={{position:"absolute", left:"50%"}}>  {(0-props.love!).toString()}</div>    */}
                    </div> 
                </div>
                <div className="plusButton" onClick={loveLock ? ()=>addToScore(2) : ()=>{}}>
                    {loveLock === false ? <FaLock/> : <FaPlus/>}
                </div>
            </div>   
        </div>  
    </div>
    )
}

export default Stats


