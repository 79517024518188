import React from 'react';
import '../App.css';
// eslint-disable-next-line
// import { ethers } from 'ethers';
import Blobbol from './Blobbol';
import GetBlobbols from './GetBlobbols';
import { BlobbelInstanceContextProvider, BlobbolGenesStringContextProvider} from '../context/blobbolContext';
// import { useBalanceContext } from "../context/blobbolContext";
import { useAccountContext } from '../context/accountContext';
import Orphanage from './Orphanage';
import Staking from './Staking';


declare const window: any;

function Main() {

  const { isConnected, setIsConnected, initialChecksDone } = useAccountContext();

  function connect() {
    window.hanaWallet!.ethereum
      .request({ method: 'eth_requestAccounts' })
      .then(() =>{
        setIsConnected(true)
      })
      .catch((error:any) => {
        if (error.code === 4001) {
          // EIP-1193 userRejectedRequest error
          console.log('Please connect to Hana.');
        } else {
          console.error(error);
        } 
    });
  }
  
  // listen for changes in the user's account
  // window.ethereum.on('accountsChanged', () => {
  //   setToggleOnAccountChange(!toggleOnAccountChange);
  //   setBlobbleBalance(0)
  // });

  // check if metamask is connected
  // useEffect(() => {
  //   (async() => {
  //     await provider!.listAccounts().then(
  //       (r)=> r.length > 0? setIsConnected(true): setIsConnected(false))
  //   })();
  //   // eslint-disable-next-line
  // }, [toggleOnAccountChange]); // effect is envoked when window.ethereum.on listener is activated

  // // set address and balance for front end
  // useEffect(()  => {
  //   (async() => {
  //     if(isConnected){
  //     try{
  //       const address = await signer!.getAddress();
  //       setAccount(address); // is setting the context for all components
  //     }catch(e){
  //       console.log('could not connect, please connect to metamask.')
  //     }
  //   }
  //   })()
  //   // eslint-disable-next-line
  // },[isConnected, toggleOnAccountChange]) // effect is envoked when window.ethereum.on listener is activated 
  
  const listenScrollEvent = () => {
    const clouds  = document.querySelectorAll<HTMLElement>("#clouds")
    let pos = window.pageXOffset
    //console.log(90 + pos*1.1 + 'px 60px, '+400+pos + 'px 140px,top center')
    clouds.forEach(cloudEl => {
      cloudEl.style.backgroundPosition = +(400+pos*Math.pow(1.06,5)) + 'px 140px, -30px 190px, top center'          
    })

  };

  window.addEventListener('scroll', listenScrollEvent);
  
  return (
    <>
    {
      initialChecksDone ?
      <>
        { isConnected ? (
          <div className="Wrapper">
            <div className="" id="clouds" >
              <div className="App" >
                <BlobbelInstanceContextProvider>
                  <BlobbolGenesStringContextProvider>
                    <Staking/>
                    <div className='wrapperGame'>
                      <div className='WrapperGreenField'>
                        <Blobbol/>
                        <GetBlobbols/>
                      </div>
                      <Orphanage/>
                    </div>
                  </BlobbolGenesStringContextProvider>
                </BlobbelInstanceContextProvider>
              </div>
            </div>
          </div>
          )
          :
          <div style={{height:"100vh", width:"100vw"}}>
            <div className="App_connect">
                <p>
                  Blobbles are cute little creatures that live on the Snow Blockchain. Their health status is also kept on the blockhain.
                  You can mint Blobbles yourself but you do need to take care of them. Otherwise
                  they will become unhappy and end up in the orphanage.<br></br><br></br>The age of a Blobble
                  is given by the number of blocks it has been on the blockchain. How old can your
                  Blobble get?<br></br><br></br>
                  Log in using your Hana Wallet and start playing!
                </p>
              <button className="buttonNormal" onClick={connect}>connect</button>
            </div>
          </div>
        }
      </>
      :
      <div style={{height:"100vh", width:"100vw", marginTop:"100px"}}>loading</div>
    }
    </>
  )
}

export default Main;
