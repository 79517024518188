import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Link from '@mui/material/Link';

export default function BasicMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        size="small"
        style={{height:"30px"}}
      >
        <p className="menuButton" style={{fontSize:"1.6em", fontStyle:"bold", color:"black"}}>menu</p>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem> <Link color="inherit" underline="none" href="/app" className="mainFont">App</Link></MenuItem>
        <MenuItem> <Link color="inherit" underline="none" href="/market" className="mainFont">Market</Link></MenuItem>
        <MenuItem> <Link color="inherit" underline="none" href="/" className="mainFont">About</Link></MenuItem>
      </Menu>
    </div>
  );
}
