import React, {useState, useEffect} from 'react'
import ReactHtmlParser from 'react-html-parser';
import { ethers } from 'ethers'
import NFTabi from '../contracts/mainBlobble.json'
import ScoresAbi from '../contracts/scoresContract.json'
import { NFTCONTRACT_ADDR, CYCLESIZE, SCORESCONTRACT_ADDR } from '../context/Constants';
import Stats from './Stats'
import foodIcon from '../icons/food.svg' 
import drinkIcon from '../icons/drink_5.svg'
import heartIcon from '../icons/heart.svg'
import { FormControl, Input,CircularProgress } from '@mui/material';
import { LoadingButton  } from '@mui/lab';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useAccountContext } from '../context/accountContext';


const invertedPercentage = (percentage:number) => {
    if (isNaN(percentage)) { 
        return 10 
    } else {
        return 1000 - percentage 
    } 
}

type BlobbolInstanceProps = {
    id: number | null
    data: string | null
}

type balances = {
    0: number | null
    1: number | null
    2: number | null
}

const balanceNums = {
    0:"Food",
    1:"Drink",
    2:"Love"
}

function BlobbolInstance(props:BlobbolInstanceProps) {
    const { signer } = useAccountContext();  
    const id = props.id
    const data = props.data
    
    const nftContract = new ethers.Contract(NFTCONTRACT_ADDR, NFTabi, signer!);     
    const scoresContract = new ethers.Contract(SCORESCONTRACT_ADDR, ScoresAbi, signer!);

    const [age, setAge] = useState(0)
    // eslint-disable-next-line
    const [latestBlock, setLatestBlock] = useState(0)
    const [score, setScore] = useState<number>(0)
    
    // these scores have been processed by invertedPercentage() 
    const [ food, setFood ] = useState(0)
    const [ drink, setDrink ] = useState(0)
    const [ love, setLove ] = useState(0)
    
    const [ selectedType, setSelectedType ] = useState<number>(0)
    const [ balances, setBalances ] = useState<balances>({0: null, 1: null, 2:null})

    const [ menuActive, setMenuActive ] = useState(false)
    const [ selectedPrice, setSelectedPrice ] = useState("")
    const [costFullScore, setCostFullScore] = useState<number>(0)
    // eslint-disable-next-line
    const [ loading, setLoading ] = useState(false) 
    

    useEffect(()=>{
        const getCost = async () => {
            let _cyclePrice = await nftContract._lifecyclePrice()
            let _healthyBalance = Math.ceil(age/CYCLESIZE) + 100
            let _thisTypeShortComing = _healthyBalance - balances[selectedType]
            let _amountOfCycles = Math.ceil(_thisTypeShortComing/100)
            let _cost = _cyclePrice * _amountOfCycles / 10 ** 18
            
            setCostFullScore(_cost)
        }
        getCost()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[age, selectedType])


    useEffect(() => {
        const getAge = async() => {
            await nftContract.getAge(id).then((res: number) => setAge(res))
        }
        getAge()
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [latestBlock])
    
    useEffect(() => {
        const getScores = async () => {
            await scoresContract.scoreIndex(props.id).then((res:any)=> {
                
                let _avg:any;
                _avg = (parseInt(res[0]) / 10 + parseInt(res[1]) / 10 + parseInt(res[2]) / 10) / 3
                
                setScore(_avg)
                
                if (res[0]/10 < 100) {
                    setFood(invertedPercentage(res[0])/10)
                } else { setFood(0) }
                
                if (res[1]/10 < 100) {
                    setDrink(invertedPercentage(res[1])/10)
                } else { setDrink(0) }

                if (res[2]/10 < 100) {
                    setLove(invertedPercentage(res[2])/10)
                } else { setLove(0) }
           
            })
        }
        getScores()
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [age, loading])


    useEffect(() => {
        const getBalances = async () => {
            let _f: number = await scoresContract.foodBalance(props.id)
            let _d: number = await scoresContract.drinkBalance(props.id)
            let _l: number = await scoresContract.loveBalance(props.id)
    
            setBalances({0: _f, 1: _d, 2: _l})
        }
        getBalances()
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [age, loading])

    const setApperance = () =>{
        const bodyMain = document.querySelectorAll<HTMLElement>(`#Blobbel_${id?.toString()}`)
        const avgScore = (food + love + drink) / 3
        
        let bodyOriginalHeight: number;

        bodyMain.forEach(body => {
            //console.log(body.children[1].children[2].children[0])
            let mainBody = body.children[1].children[3].children[0] as HTMLElement         
            bodyOriginalHeight = parseInt(mainBody.style.height.split('px')[0] )
        })
   
        // mouth sadder, also body height decreases
        // * score is the amount of pixels the bar is moved
        if (avgScore  > 73 ) {
            bodyMain.forEach(body => {
                let mainBody = body.children[1].children[3].children[0] as HTMLElement
                let mouth = body.children[1].children[3].children[0].children[4] as HTMLElement
                let sadMouth = "/ 100% 100% 50% 1%";
                let mouth_radius_1 = mouth.style.borderRadius.split('/')[0] 
                mouth.style.borderRadius = mouth_radius_1 + sadMouth
                
                if (bodyOriginalHeight > 110) {
                    mainBody.style.height = (bodyOriginalHeight * 0.8).toString() + 'px'
                } 
            })
        }
        // first a bit sad --> only eyes sad, mouth not yet
        // * score is the amount of pixels the bar is moved
        if (avgScore > 45) {
            bodyMain.forEach(body => {
                let leftEye = body.children[1].children[3].children[0].children[2] as HTMLElement
                let rightEye = body.children[1].children[3].children[0].children[3] as HTMLElement

                let sadLeftEye = "90% 10% 40% 47% / 90% 47% 47% 40%"
                let sadRightEye = "8% 48% 21% 24% / 62% 68% 33% 42%"
                 
                leftEye.style.borderRadius = sadLeftEye
                rightEye.style.borderRadius = sadRightEye
                leftEye.style.top = "20px"
                rightEye.style.top = "20px"
            })
        }

    }
    
    useEffect(() => {
        try{
            setApperance()
        } catch(e) {
            console.log(e)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [food,drink,love,loading])

        
    const handleClick = async (input:string) => {
        let _bnUSDPaid = ethers.utils.parseUnits(input, 18)
        // console.log(_bnUSDPaid)
        let options = {
            gasLimit : 9900000,
        }
        try { 
            const tx = await nftContract.addToBalanceWithBnUSD(id, selectedType, _bnUSDPaid, options) 
            console.log(tx)
        } catch(e:any){ 
            console.log(e)
            // if(e.data?.code === -32603){
            //     console.log(e.data)
            // }

        } 
    }

    const handleChange = (event) => {
        setSelectedPrice(event.target.value)
    }

    const handleTypeChange = (event) => {
        setSelectedType(event.target.value as number)
    }

    return (
        <div id={`Blobbel_${id?.toString()}`} 
            className={ menuActive ? "blobbolRenderContainer blobbleRenderContainerMenuOpened" : "blobbolRenderContainer"}
            >
            <div className={`statsWrapper`} style={{gridArea: "statsContainer"}}>
                <div className={`statsContainer ${menuActive && "statsContainerOpen"}`}>
                    <div style={{display:"flex", width:"100%"}}>
                        <div style={{display:"flex", flexDirection:"column", justifyContent:"space-evenly", height:"200px", width:"50px"}}> 
                            <img src={foodIcon} alt="food" style={{width:"30px", transform:"rotate(10deg)", justifySelf:"center", alignSelf:"center"}} />
                            <img src={drinkIcon} alt="drink" style={{width:"37px", transform:"rotate(-13deg)" , justifySelf:"center", alignSelf:"center"}} />
                            <img src={heartIcon} alt="love" style={{width:"30px", transform:"rotate(5deg)" , justifySelf:"center", alignSelf:"center"}} />
                        </div>
                        <Stats id={id!} age={age} food={food} drink={drink} love={love} menustate={menuActive}/>
                    </div>
                </div>
            </div>
            
            <div id="titleandblobbol" style={{gridArea: "blobbleContainer", marginTop: "2em"}}
                onClick={()=>setMenuActive(!menuActive)}>
                <div className="titleBlobbol" style={{gridArea: "id"}}>blobble id:{id}</div>
                <div className="undertitleBlobbol" style={{gridArea: "age"}}>age: {age.toString()} blocks</div>
                <div className="undertitleBlobbol">avg health score: {Math.round(score)}</div>
                <div  className="blobbolCharacter">{ReactHtmlParser(data!)}</div>
            </div>             
            <div className={menuActive? "infoButtonContainerClosed infoButtonContainerOpen": "infoButtonContainerClosed"}
            style={{gridArea: "scoreContainer"}}>
                <div style={{ 
                    zIndex:99, 
                    display:'flex', 
                    flexDirection:'column'}}>
        
                    <FormControl >
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={selectedType}
                            onChange={handleTypeChange}
                            row
                        >
                            <FormControlLabel value={0} control={<Radio size='small' color="default"/>} label="Food" />
                            <FormControlLabel value={1} control={<Radio size='small' color="default"/>} label="Drink" />
                            <FormControlLabel value={2} control={<Radio size='small' color="default"/>} label="Love" />
                        </RadioGroup>
                    </FormControl>
                    <div style={{textAlign:"start"}}>
                        
                        <p style={{marginBottom:"2px"}}>Balance {balances[selectedType] && balances[selectedType].toString()}</p>
                        
                        {/* the * 100 stands for one full balance */}
                        <p style={{marginTop:"2px"}}>Healthy Balance {Math.ceil(age/CYCLESIZE) + 100}</p>
                        <p style={{marginTop:"2px"}}>Cost of Healthy {balanceNums[selectedType]} Balance:<br></br>
                        
                        {/* { (((Math.ceil(age/CYCLESIZE) * 100 + 100) - balances[selectedType]) / 100).toFixed(2)}  */}
                        {costFullScore} ICZ

                        </p>

                    </div>
                    <div style={{display:'flex', flexDirection:'row', height:'50px', alignItems:'center'}}>
                        <p style={{textAlign: 'left',}}>Add</p>
                        <FormControl style={{width: '68px', marginLeft:"12px", marginRight:"12px"}}>
                            <Input
                                size="small"
                                type="number"
                                placeholder='0'
                                onChange={handleChange}
                                value={selectedPrice}
                            />
                        </FormControl>
                        {/* <p style={{marginRight:'20px'}}>total: {Math.ceil(totalPrice)} ICZ</p> */}
                        <div style={{marginBottom:'6px', background:"#282c34", color:"white" ,overflow:"hidden", borderRadius:"3px", marginLeft:"40px"}} >     
                            <LoadingButton 
                                loading={loading}
                                color="inherit"
                                size="small"
                                loadingIndicator={<CircularProgress size={18} style={{color:"white"}}/>}
                                onClick={()=>handleClick(selectedPrice.toString())}
                                disabled={parseInt(selectedPrice) < 1}
                            >
                                Pay
                            </LoadingButton>
                        </div>
                    </div>
                </div>
            </div>   
        </div>

    )
}

export default BlobbolInstance
